import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { MainButton } from './MainButton';

export const ContactFormSection = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();

  const formRef = useRef();

  const formSubmit = (values) => {
    emailjs
      .sendForm(
        'service_cs34fhk',
        'template_cue133i',
        formRef.current,
        'a_h3L5cUKDrslC4HB'
      )
      .then(
        (result) => {
          console.log(result);
          navigate('/thankyou');
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <section id="contactFormSection" className="bg-[#eeeeee] py-8">
      <div className="max-w-[1200px] mx-auto">
        <h2 className="text-[40px] text-center font-bold">
          {t('contactSectionHeader')}
        </h2>
        <p className="text-center mb-8 mt-4">{t('contactSectionText')}</p>
        <form
          id="contactForm"
          onSubmit={handleSubmit(formSubmit)}
          className="w-[55%] mx-auto flex flex-col justify-around items-center md:flex-row md:w-[70%]"
          ref={formRef}
        >
          <input
            {...register('name')}
            placeholder={t('contactFormName')}
            className="px-6 py-3 outline-none border-black border bg-gray-200 w-full mb-4 md:mb-0 md:mr-5"
          />

          <input
            {...register('phone', { required: true })}
            placeholder={t('contactFormPhoneNumber')}
            type="number"
            className="px-6 py-3 outline-none border-black border bg-gray-200 w-full mb-4 md:mb-0 md:mr-5"
          />

          {errors.exampleRequired && <span>This field is required</span>}

          <MainButton
            text={t('contactFormButtonText')}
            className="hover:bg-secondary hover:border-secondary  px-10 py-3 rounded-none hover:text-white w-full md:w-[80%]"
          />
        </form>
      </div>
    </section>
  );
};
