import React from 'react';

// import exampleImage from "../../Assets/carousel-1.jpg";
// import exampleSmile from "../../Assets/carousel-2.png";
// import exampleBack from "../../Assets/carousel-3.jpg";
import Carousel from '../../components/Swiper/Swiper';
import { useTranslation } from 'react-i18next';

function ResultsSliderSection() {
  const { t } = useTranslation();
  const slides = [
    { backgroundImage: 'bg-carouselImage1' },
    { backgroundImage: 'bg-carouselImage2' },
    { backgroundImage: 'bg-carouselImage3' },
  ];

  return (
    <section>
      <div className="innerWrapper pb-10">
        <div className="bg-white w-[100%] py-12 text-[30px] font-bold flex justify-center items-center">
          {t('emsResultsPageBeforeAndAfter')}
        </div>
        <div className="w-[100%] flex justify-center items-center ">
          <Carousel data={slides} />
          <div className="flex items-center justify-center">
            {/* <div className="carousel bg-center bg-cover duration-500  w-[200px] h-[200px] bg-exampleImage"></div> */}
            {/* <img src={exampleImage} alt="example" className="w-[50%] h-[50%]" /> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ResultsSliderSection;
