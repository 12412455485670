import React from 'react';
import ResultsCard from './ResultsCard';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ResultsSection() {
  const { t } = useTranslation();

  return (
    <section>
      <div className=" bg-[#eeeeee] py-14">
        <div className="titleWrapper flex flex-col items-center text-center">
          <h2 className="text-black duration-[1000ms] text-[32px] font-bold">
            {t('emsResultsHeader')}
          </h2>
          <p className="text-black text-[18px] delay-1000 duration-[1000ms]">
            {t('emsResultsParagraph')}
          </p>
          <div className="cardWrapper justify-items-center grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-12 mt-8 justify-center items-center">
            <ResultsCard
              headerTitle="emsResultsCardHeaderOne"
              secondaryTitle="emsResultsCardParagraphOne"
              bgImage="bg-resultsCardImage"
            />
            <ResultsCard
              headerTitle="emsResultsCardHeaderTwo"
              secondaryTitle="emsResultsCardParagraphTwo"
              bgImage="bg-resultsCardImageSecond"
            />
            <ResultsCard
              headerTitle="emsResultsCardHeaderThree"
              secondaryTitle="emsResultsCardParagraphThree"
              bgImage="bg-resultsCardImageThird"
            />
            <ResultsCard
              headerTitle="emsResultsCardHeaderFour"
              secondaryTitle="emsResultsCardParagraphFour"
              bgImage="bg-resultsCardImageFourth"
            />
            <ResultsCard
              headerTitle="emsResultsCardHeaderFive"
              secondaryTitle="emsResultsCardParagraphFive"
              bgImage="bg-resultsCardImageFifth"
            />
            <ResultsCard
              headerTitle="emsResultsCardHeaderSix"
              bgImage="bg-resultsCardImageSixth"
            />

            <p className="lg:col-span-2 mb-8 lg:ml-7 text-center lg:text-left text-[25px] w-[330px] lg:w-[800px] h-[60px]">
              {t('emsResultsEndParagraph')}
            </p>
            <Link to="/aboutEms">
              <button className="w-[340px] h-[60px] border-solid border-[2.5px] border-[#b3d235] text-[#b3d235] hover:text-white hover:border-none hover:bg-[#fe7419] rounded-[2%] font-bold transition-all duration-300">
                {t('aboutEmsResults')}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ResultsSection;

// color choice green - #b3d235 orange - #fe7419
