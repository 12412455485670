import React from 'react';
import { useTranslation } from 'react-i18next';

function ResultsSectoinThree({
  resultsSectionHeader,
  resultsSectionParagraph,
  resultSectionBackground,
}) {
  const { t } = useTranslation();
  return (
    <div
      id="resultSectionThree"
      className={`${resultSectionBackground} h-[650px] w-[100%] px-4 py-16`}
    >
      <div className="h-[100%] w-[100%] border-y-2 border-t-black border-b-black text-center text-[24px] font-thin flex flex-col items-center justify-center">
        <h2 className="text-[14px] font-bold tracking-[3px] text-white bg-black py-2 px-6 mb-10">
          {t(resultsSectionHeader)}
        </h2>
        <p>{t(resultsSectionParagraph)}</p>
      </div>
    </div>
  );
}

export default ResultsSectoinThree;
