import { useTranslation } from 'react-i18next';
import AccordionDemo, {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../components/Accordion';
import ArrowUp from '../../components/ArrowUp';
import FloatingLink from '../../components/FloatingLink';
import { Header } from '../../components/Header';
import Hero from '../../components/Hero';
import { VideoSection } from '../../components/VideoSection';
import FooterSection from '../../components/FooterSection';
import CodeLuminis from '../../components/CodeLuminis';
import BetaTest from '../../components/BetaTest';

const Faq = () => {
  const { t } = useTranslation();
  return (
    <>
      <BetaTest />
      <FloatingLink />
      <ArrowUp />
      <Header />
      <Hero
        backgroundImage="bg-faqHeroBackground"
        backgroundHeight="h-[500px]"
        backgroundSize="bg-cover bg-local xl:bg-fixed"
        backgroundOverlay="bg-[rgb(0,0,0,0.5)]"
        isAnimated={false}
        arrowOn={false}
        secondHeaderTitle="faqHeroTitle"
      />
      <section className="bg-gray-400 py-4 px-2">
        <div className="sm:max-w-[80%] md:max-w-[70%] lg:max-w-[50%] mx-auto">
          <div className="mx-auto mb-8">
            <AccordionDemo>
              <AccordionItem value="item-1" margin="mb-1">
                <AccordionTrigger
                  backgroundColor="bg-white"
                  fontWeight="font-bold"
                  py="py-10"
                  iconColor="text-secondary"
                  iconSize="1.5rem"
                >
                  <h5 className="text-base sm:text-xl text-left">
                    {t('faqPartOneAccordionOneHeader')}
                  </h5>
                </AccordionTrigger>
                <AccordionContent margin="ml-0">
                  <div className="bg-white p-6 font-thin">
                    <p className="mb-4">
                      {t('faqPartOneAccordionOneParagraphOne')}
                    </p>
                    <p className="mb-4">
                      {t('faqPartOneAccordionOneParagraphTwo')}
                    </p>
                    <p className="mb-4">
                      {t('faqPartOneAccordionOneParagraphThree')}
                    </p>
                    <ul className="list-disc ml-4">
                      <li>{t('faqPartOneAccordionOneListOne')}</li>
                      <li>{t('faqPartOneAccordionOneListTwo')}</li>
                      <li>{t('faqPartOneAccordionOneListThree')}</li>
                      <li>{t('faqPartOneAccordionOneListFour')}</li>
                      <li>{t('faqPartOneAccordionOneListFive')}</li>
                      <li>{t('faqPartOneAccordionOneListSix')}</li>
                      <li>{t('faqPartOneAccordionOneListSeven')}</li>
                      <li>{t('faqPartOneAccordionOneListEight')}</li>
                    </ul>
                  </div>
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="item-2" margin="mb-1">
                <AccordionTrigger
                  backgroundColor="bg-white"
                  fontWeight="font-bold"
                  py="py-10"
                  iconColor="text-secondary"
                  iconSize="1.5rem"
                >
                  <h5 className="text-base sm:text-xl text-left">
                    {t('faqPartOneAccordionTwoHeader')}
                  </h5>
                </AccordionTrigger>
                <AccordionContent margin="ml-0">
                  <div className="bg-white p-6 font-thin">
                    <p className="mb-4">
                      {t('faqPartOneAccordionTwoParagraphOne')}
                    </p>
                    <p className="mb-4">
                      {t('faqPartOneAccordionTwoParagraphTwo')}
                    </p>
                    <p className="mb-4">
                      {t('faqPartOneAccordionTwoParagraphThree')}
                    </p>
                  </div>
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="item-3" margin="mb-1">
                <AccordionTrigger
                  backgroundColor="bg-white"
                  fontWeight="font-bold"
                  py="py-10"
                  iconColor="text-secondary"
                  iconSize="1.5rem"
                >
                  <h5 className="text-base sm:text-xl text-left">
                    {t('faqPartOneAccordionThreeHeader')}
                  </h5>
                </AccordionTrigger>
                <AccordionContent margin="ml-0">
                  <div className="bg-white p-6 font-thin">
                    <p className="mb-4">
                      {t('faqPartOneAccordionThreeParagraphOne')}
                    </p>
                    <p className="mb-4">
                      {t('faqPartOneAccordionThreeParagraphTwo')}
                    </p>
                    <p className="mb-4">
                      {t('faqPartOneAccordionThreeParagraphThree')}
                    </p>
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-4" margin="mb-1">
                <AccordionTrigger
                  backgroundColor="bg-white"
                  fontWeight="font-bold"
                  py="py-10"
                  iconColor="text-secondary"
                  iconSize="1.5rem"
                >
                  <h5 className="text-base sm:text-xl text-left">
                    {t('faqPartOneAccordionFourHeader')}
                  </h5>
                </AccordionTrigger>
                <AccordionContent margin="ml-0">
                  <div className="bg-white p-6 font-thin">
                    <p className="mb-4">
                      {t('faqPartOneAccordionFourParagraphOne')}
                    </p>
                    <p className="mb-4">
                      {t('faqPartOneAccordionFourParagraphTwo')}
                    </p>
                    <p className="mb-4">
                      {t('faqPartOneAccordionFourParagraphThree')}
                    </p>
                    <p className="mb-4">
                      {t('faqPartOneAccordionFourParagraphFour')}
                    </p>
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-5" margin="mb-1">
                <AccordionTrigger
                  backgroundColor="bg-white"
                  fontWeight="font-bold"
                  py="py-10"
                  iconColor="text-secondary"
                  iconSize="1.5rem"
                >
                  <h5 className="text-base sm:text-xl text-left">
                    {t('faqPartOneAccordionFiveHeader')}
                  </h5>
                </AccordionTrigger>
                <AccordionContent margin="ml-0">
                  <div className="bg-white p-6 font-thin">
                    <p className="mb-4">
                      {t('faqPartOneAccordionFiveParagraphOne')}
                    </p>
                    <p className="mb-4">
                      {t('faqPartOneAccordionFiveParagraphTwo')}
                    </p>
                    <p className="mb-4">
                      {t('faqPartOneAccordionFiveParagraphThree')}
                    </p>
                  </div>
                </AccordionContent>
              </AccordionItem>
            </AccordionDemo>
          </div>
          <div className=" mx-auto mb-4">
            <AccordionDemo>
              <AccordionItem value="item-1" margin="mb-1">
                <AccordionTrigger
                  backgroundColor="bg-white"
                  fontWeight="font-bold"
                  py="py-10"
                  iconColor="text-main"
                  iconSize="1.5rem"
                >
                  <h5 className="text-base sm:text-xl text-left">
                    {t('faqPartTwoAccordionOneHeader')}
                  </h5>
                </AccordionTrigger>
                <AccordionContent margin="ml-0">
                  <div className="bg-white p-6 font-thin">
                    <p className="mb-4">
                      {t('faqPartTwoAccordionOneParagraphOne')}
                    </p>
                    <p className="mb-4">
                      {t('faqPartTwoAccordionOneParagraphTwo')}
                    </p>
                  </div>
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="item-2" margin="mb-1">
                <AccordionTrigger
                  backgroundColor="bg-white"
                  fontWeight="font-bold"
                  py="py-10"
                  iconColor="text-main"
                  iconSize="1.5rem"
                >
                  <h5 className="text-base sm:text-xl text-left">
                    {t('faqPartTwoAccordionTwoHeader')}
                  </h5>
                </AccordionTrigger>
                <AccordionContent margin="ml-0">
                  <div className="bg-white p-6 font-thin">
                    <ul className="list-disc ml-4 mb-4">
                      <li>{t('faqPartTwoAccordionTwoParagraphOne')}</li>
                      <li>{t('faqPartTwoAccordionTwoParagraphTwo')}</li>
                    </ul>
                  </div>
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="item-3" margin="mb-1">
                <AccordionTrigger
                  backgroundColor="bg-white"
                  fontWeight="font-bold"
                  py="py-10"
                  iconColor="text-main"
                  iconSize="1.5rem"
                >
                  <h5 className="text-base sm:text-xl text-left">
                    {t('faqPartTwoAccordionThreeHeader')}
                  </h5>
                </AccordionTrigger>
                <AccordionContent margin="ml-0">
                  <div className="bg-white p-6 font-thin">
                    <p className="mb-4">
                      {t('faqPartTwoAccordionThreeParagraphOne')}
                    </p>
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-4" margin="mb-1">
                <AccordionTrigger
                  backgroundColor="bg-white"
                  fontWeight="font-bold"
                  py="py-10"
                  iconColor="text-main"
                  iconSize="1.5rem"
                >
                  <h5 className="text-base sm:text-xl text-left">
                    {t('faqPartTwoAccordionFourHeader')}
                  </h5>
                </AccordionTrigger>
                <AccordionContent margin="ml-0">
                  <div className="bg-white p-6 font-thin">
                    <p className="mb-4">
                      {t('faqPartTwoAccordionFourParagraphOne')}
                    </p>
                    <p className="mb-4">
                      {t('faqPartTwoAccordionFourParagraphTwo')}
                    </p>
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-5" margin="mb-1">
                <AccordionTrigger
                  backgroundColor="bg-white"
                  fontWeight="font-bold"
                  py="py-10"
                  iconColor="text-main"
                  iconSize="1.5rem"
                >
                  <h5 className="text-base sm:text-xl text-left">
                    {t('faqPartTwoAccordionFiveHeader')}
                  </h5>
                </AccordionTrigger>
                <AccordionContent margin="ml-0">
                  <div className="bg-white p-6 font-thin">
                    <p className="mb-4">
                      {t('faqPartTwoAccordionFiveParagraphOne')}
                    </p>
                  </div>
                </AccordionContent>
              </AccordionItem>
            </AccordionDemo>
          </div>
          <div className=" mx-auto mb-4">
            <AccordionDemo>
              <AccordionItem value="item-1" margin="mb-1">
                <AccordionTrigger
                  backgroundColor="bg-white"
                  fontWeight="font-bold"
                  py="py-10"
                  iconColor="text-secondary"
                  iconSize="1.5rem"
                >
                  <h5 className="text-base sm:text-xl text-left">
                    {t('faqPartThreeAccordionOneHeader')}
                  </h5>
                </AccordionTrigger>
                <AccordionContent margin="ml-0">
                  <div className="bg-white p-6 font-thin">
                    <p className="mb-4">
                      <span className="font-bold">
                        {t('faqPartThreeAccordionOneSpanOne')}
                      </span>
                      {t('faqPartThreeAccordionOneParagraphOne')}
                    </p>
                    <p className="mb-4">
                      {t('faqPartThreeAccordionOneParagraphTwo')}
                    </p>
                  </div>
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="item-2" margin="mb-1">
                <AccordionTrigger
                  backgroundColor="bg-white"
                  fontWeight="font-bold"
                  py="py-10"
                  iconColor="text-secondary"
                  iconSize="1.5rem"
                >
                  <h5 className="text-base sm:text-xl text-left">
                    {t('faqPartThreeAccordionTwoHeader')}
                  </h5>
                </AccordionTrigger>
                <AccordionContent margin="ml-0">
                  <div className="bg-white p-6 font-thin">
                    <p className="mb-4">
                      {t('faqPartThreeAccordionTwoParagraphOne')}
                    </p>
                    <p className="mb-4 font-bold">
                      {t('faqPartThreeAccordionTwoParagraphTwo')}
                    </p>
                  </div>
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="item-3" margin="mb-1">
                <AccordionTrigger
                  backgroundColor="bg-white"
                  fontWeight="font-bold"
                  py="py-10"
                  iconColor="text-secondary"
                  iconSize="1.5rem"
                >
                  <h5 className="text-base sm:text-xl text-left">
                    {t('faqPartThreeAccordionThreeHeader')}
                  </h5>
                </AccordionTrigger>
                <AccordionContent margin="ml-0">
                  <div className="bg-white p-6 font-thin">
                    <p className="mb-4">
                      {t('faqPartThreeAccordionThreeParagraphOne')}
                    </p>
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-4" margin="mb-1">
                <AccordionTrigger
                  backgroundColor="bg-white"
                  fontWeight="font-bold"
                  py="py-10"
                  iconColor="text-secondary"
                  iconSize="1.5rem"
                >
                  <h5 className="text-base sm:text-xl text-left">
                    {t('faqPartThreeAccordionFourHeader')}
                  </h5>
                </AccordionTrigger>
                <AccordionContent margin="ml-0">
                  <div className="bg-white p-6 font-thin">
                    <p className="mb-4">
                      <span className="font-bold">
                        {t('faqPartThreeAccordionFourSpanOne')}
                      </span>
                      {t('faqPartThreeAccordionFourParagraphOne')}
                    </p>
                    <p className="mb-4">
                      {t('faqPartThreeAccordionFourParagraphTwo')}
                    </p>
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-5" margin="mb-1">
                <AccordionTrigger
                  backgroundColor="bg-white"
                  fontWeight="font-bold"
                  py="py-10"
                  iconColor="text-secondary"
                  iconSize="1.5rem"
                >
                  <h5 className="text-base sm:text-xl text-left">
                    {t('faqPartThreeAccordionFiveHeader')}
                  </h5>
                </AccordionTrigger>
                <AccordionContent margin="ml-0">
                  <div className="bg-white p-6 font-thin">
                    <p className="mb-4">
                      <span className="font-bold">
                        {t('faqPartThreeAccordionFiveSpanOne')}
                      </span>
                      {t('faqPartThreeAccordionFiveParagraphOne')}
                    </p>
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-6" margin="mb-1">
                <AccordionTrigger
                  backgroundColor="bg-white"
                  fontWeight="font-bold"
                  py="py-10"
                  iconColor="text-secondary"
                  iconSize="1.5rem"
                >
                  <h5 className="text-base sm:text-xl text-left">
                    {t('faqPartThreeAccordionSixHeader')}
                  </h5>
                </AccordionTrigger>
                <AccordionContent margin="ml-0">
                  <div className="bg-white p-6 font-thin">
                    <p className="mb-4">
                      <span className="font-bold">
                        {t('faqPartThreeAccordionSixSpanOne')}
                      </span>
                      {t('faqPartThreeAccordionSixParagraphOne')}
                    </p>
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-7" margin="mb-1">
                <AccordionTrigger
                  backgroundColor="bg-white"
                  fontWeight="font-bold"
                  py="py-10"
                  iconColor="text-secondary"
                  iconSize="1.5rem"
                >
                  <h5 className="text-base sm:text-xl text-left">
                    {t('faqPartThreeAccordionSevenHeader')}
                  </h5>
                </AccordionTrigger>
                <AccordionContent margin="ml-0">
                  <div className="bg-white p-6 font-thin">
                    <p className="mb-4">
                      {t('faqPartThreeAccordionSevenParagraphOne')}
                    </p>
                    <p className="mb-4">
                      {t('faqPartThreeAccordionSevenParagraphTwo')}
                    </p>
                    <p className="mb-4">
                      {t('faqPartThreeAccordionSevenParagraphThree')}
                    </p>
                  </div>
                </AccordionContent>
              </AccordionItem>
            </AccordionDemo>
          </div>
          <div className=" mx-auto mb-4">
            <AccordionDemo>
              <AccordionItem value="item-1" margin="mb-1">
                <AccordionTrigger
                  backgroundColor="bg-white"
                  fontWeight="font-bold"
                  py="py-10"
                  iconColor="text-main"
                  iconSize="1.5rem"
                >
                  <h5 className="text-base sm:text-xl text-left">
                    {t('faqPartFourAccordionOneHeader')}
                  </h5>
                </AccordionTrigger>
                <AccordionContent margin="ml-0">
                  <div className="bg-white p-6 font-thin">
                    <p className="mb-4">
                      {t('faqPartFourAccordionOneParagraphOne')}
                    </p>
                    <p className="mb-4">
                      {t('faqPartFourAccordionOneParagraphTwo')}
                    </p>
                  </div>
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="item-2" margin="mb-1">
                <AccordionTrigger
                  backgroundColor="bg-white"
                  fontWeight="font-bold"
                  py="py-10"
                  iconColor="text-main"
                  iconSize="1.5rem"
                >
                  <h5 className="text-base sm:text-xl text-left">
                    {t('faqPartFourAccordionTwoHeader')}
                  </h5>
                </AccordionTrigger>
                <AccordionContent margin="ml-0">
                  <div className="bg-white p-6 font-thin">
                    <p className="mb-4">
                      {t('faqPartFourAccordionTwoParagraphOne')}
                    </p>
                    <ul className="list-disc ml-4 mb-4">
                      <li>{t('faqPartFourAccordionTwoListOne')}</li>
                      <li>{t('faqPartFourAccordionTwoListTwo')}</li>
                      <li>{t('faqPartFourAccordionTwoListThree')}</li>
                      <li>{t('faqPartFourAccordionTwoListFour')}</li>
                      <li>{t('faqPartFourAccordionTwoListFive')}</li>
                      <li>{t('faqPartFourAccordionTwoListSix')}</li>
                    </ul>
                    <p className="mb-4">
                      {t('faqPartFourAccordionTwoParagraphTwo')}
                    </p>
                    <ul className="list-disc ml-4 mb-4">
                      <li>{t('faqPartFourAccordionTwoListSeven')}</li>
                      <li>{t('faqPartFourAccordionTwoListEight')}</li>
                      <li>{t('faqPartFourAccordionTwoListNine')}</li>
                      <li>{t('faqPartFourAccordionTwoListTen')}</li>
                      <li>{t('faqPartFourAccordionTwoListEleven')}</li>
                      <li>{t('faqPartFourAccordionTwoListTwelve')}</li>
                      <li>{t('faqPartFourAccordionTwoListThirteen')}</li>
                      <li>{t('faqPartFourAccordionTwoListFourteen')}</li>
                    </ul>
                  </div>
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="item-3" margin="mb-1">
                <AccordionTrigger
                  backgroundColor="bg-white"
                  fontWeight="font-bold"
                  py="py-10"
                  iconColor="text-main"
                  iconSize="1.5rem"
                >
                  <h5 className="text-base sm:text-xl text-left">
                    {t('faqPartFourAccordionThreeHeader')}
                  </h5>
                </AccordionTrigger>
                <AccordionContent margin="ml-0">
                  <div className="bg-white p-6 font-thin">
                    <p className="mb-4">
                      <span className="font-bold">
                        {t('faqPartFourAccordionThreeSpanOne')}
                      </span>
                      {t('faqPartFourAccordionThreeParagraphOne')}
                    </p>
                    <p className="mb-4">
                      {t('faqPartFourAccordionThreeParagraphTwo')}
                    </p>
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-4" margin="mb-1">
                <AccordionTrigger
                  backgroundColor="bg-white"
                  fontWeight="font-bold"
                  py="py-10"
                  iconColor="text-main"
                  iconSize="1.5rem"
                >
                  <h5 className="text-base sm:text-xl text-left">
                    {t('faqPartFourAccordionFourHeader')}
                  </h5>
                </AccordionTrigger>
                <AccordionContent margin="ml-0">
                  <div className="bg-white p-6 font-thin">
                    <p className="mb-4">
                      {t('faqPartFourAccordionFourParagraphOne')}
                    </p>
                    <p className="mb-4">
                      {t('faqPartFourAccordionFourParagraphTwo')}
                    </p>
                    <p className="mb-4">
                      {t('faqPartFourAccordionFourParagraphThree')}
                    </p>
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-5" margin="mb-1">
                <AccordionTrigger
                  backgroundColor="bg-white"
                  fontWeight="font-bold"
                  py="py-10"
                  iconColor="text-main"
                  iconSize="1.5rem"
                >
                  <h5 className="text-base sm:text-xl text-left">
                    {t('faqPartFourAccordionFiveHeader')}
                  </h5>
                </AccordionTrigger>
                <AccordionContent margin="ml-0">
                  <div className="bg-white p-6 font-thin">
                    <p className="mb-4">
                      {t('faqPartFourAccordionFiveParagraphOne')}
                    </p>
                    <a
                      href="https://docs.wixstatic.com/ugd/adc5b1_e11fa4dbcb5143d8bd9c5350636bc1dc.pdf"
                      className="text-main"
                    >{`>>01/2010 | Medical fitness and healthcare`}</a>
                    <br />
                    <a
                      href="https://docs.wixstatic.com/ugd/adc5b1_7a64680f942f4128ac4f453bf09dd691.pdf"
                      className="text-main"
                    >{`>>01/2013 | Medical fitness and healthcare`}</a>
                  </div>
                </AccordionContent>
              </AccordionItem>
            </AccordionDemo>
          </div>
        </div>
      </section>
      <VideoSection
        header="faqVideoSectionHeader"
        paragraph="faqVideoSectionParagraph"
        buttonText="researchResultsVideoSectionButtonText"
      />
      <FooterSection />
      <CodeLuminis />
    </>
  );
};

export default Faq;
