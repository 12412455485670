import { Link } from 'react-router-dom';
import Logo from '../Assets/justfit_logo_vector.png';
import { Divide as Hamburger } from 'hamburger-react';
import { GoMail } from 'react-icons/go';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationMenu } from './NavigationMenu';
import GE from 'country-flag-icons/react/3x2/GE';
import GB from 'country-flag-icons/react/3x2/GB';
import { AiOutlineClose } from 'react-icons/ai';
import { Select, Option } from '@material-tailwind/react';
import AccordionDemo, {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from './Accordion';

export const Header = () => {
  const [toggleBurger, setToggleBurger] = useState(false);
  const navBarRef = useRef();
  const hamburgerRef = useRef();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const outsideClickHandler = (e) => {
      if (!navBarRef.current) return;
      if (
        !navBarRef.current.contains(e.target) &&
        !hamburgerRef.current.contains(e.target)
      ) {
        setToggleBurger(false);
      }
    };

    document.addEventListener('mousedown', outsideClickHandler);
  });
  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  return (
    <>
      <header>
        <div>
          <div className="max-w-[1200px] mr-auto ml-auto">
            <div className="flex justify-between items-center py-2 px-5">
              <Link to="/">
                <img src={Logo} alt="JustFit Logo" className="block" />
              </Link>

              <div className="hidden items-center md:flex ml-auto mr-10">
                <GoMail size="30px" />
                <a
                  href="mailto:call.justfit@gmail.com"
                  className="font-medium mr-10 ml-3"
                >
                  Info@justfit.ge
                </a>
              </div>

              <div>
                <Select
                  label={t('chooseLanguage')}
                  onChange={(e) => handleLanguageChange(e)}
                  color="light-green"
                  className="text-main"
                  selected={(element) =>
                    element &&
                    React.cloneElement(element, {
                      className:
                        'flex items-center px-0 gap-2 pointer-events-none',
                    })
                  }
                >
                  <Option
                    value="en"
                    className="flex justify-between items-center"
                  >
                    <div>EN</div>
                    <GB className="w-8 h-8" />
                  </Option>
                  <Option
                    value="ka"
                    className="flex justify-between items-center"
                  >
                    <div>GEO</div> <GE className="w-8 h-8" />
                  </Option>
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div
          className="hidden lg:flex justify-center items-center w-full z-[9999] transition-opacity duration-1000"
          id="menu"
        >
          <NavigationMenu />
          <div className="hidden ml-auto" id="scrolledLangMenu">
            <div>
              <Select
                label={t('chooseLanguage')}
                onChange={(e) => handleLanguageChange(e)}
                color="light-green"
                className="text-main"
                selected={(element) =>
                  element &&
                  React.cloneElement(element, {
                    className:
                      'flex items-center px-0 gap-2 pointer-events-none',
                  })
                }
              >
                <Option
                  value="en"
                  className="flex justify-between items-center"
                >
                  <div>UKR </div>
                  <GB className="w-8 h-8" />
                </Option>
                <Option
                  value="ka"
                  className="flex justify-between items-center"
                >
                  <div>GEO</div> <GE className="w-8 h-8" />
                </Option>
              </Select>
            </div>
          </div>
        </div>
        <div className="lg:hidden flex justify-end pr-3">
          <div ref={hamburgerRef}>
            <Hamburger
              toggle={setToggleBurger}
              toggled={toggleBurger}
              color="#b3d235"
            />
          </div>
        </div>
        <div
          className={
            toggleBurger
              ? 'fixed left-0 top-0 w-[60%]  h-full bg-main ease-in-out duration-500 z-50 text-white lg:hidden'
              : 'fixed left-[-100%]'
          }
          ref={navBarRef}
        >
          <div className="flex justify-between items-center px-5">
            <Link to="/">
              <img src={Logo} alt="JustFit Logo" className="block" />
            </Link>
            <AiOutlineClose
              color="white"
              className="cursor-pointer"
              onClick={() => setToggleBurger(false)}
              size={25}
            />
          </div>
          <ul>
            <AccordionDemo>
              <AccordionItem value="item-1">
                <AccordionTrigger backgroundColor="bg-main">
                  {t('aboutEMS')}
                </AccordionTrigger>
                <AccordionContent margin="ml-5">
                  <li>
                    <Link to="/aboutems">{t('whatIsEMS')}</Link>
                  </li>
                  <li>
                    <Link to="/howToEMS">{t('howWorksEMS')}</Link>
                  </li>
                  <li>
                    <Link to="/researchResults">{t('researchResults')}</Link>
                  </li>
                </AccordionContent>
              </AccordionItem>
            </AccordionDemo>
            <li className="px-5 border-b border-white h-[45px] flex justify-center flex-col">
              <Link to="/results">{t('results')}</Link>
            </li>
            <li className="px-5 border-b border-white h-[45px] flex justify-center flex-col">
              <Link to="/photosandvideos">{t('photoAndVideo')}</Link>
            </li>
            <li className="px-5 border-b border-white h-[45px] flex justify-center flex-col">
              <Link to="/faq">{t('questionsAndAnswers')}</Link>
            </li>
            <li className="px-5 border-b border-white h-[45px] flex justify-center flex-col">
              <Link to="/contact">{t('contacts')}</Link>
            </li>
          </ul>
        </div>
      </header>
    </>
  );
};
