import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';
import { clsx } from 'clsx';
import React from 'react';

import { IoMdArrowDropdown } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const NavigationMenu = () => {
  const { pathname } = useLocation();
  console.log(pathname);
  const { t } = useTranslation();
  return (
    <NavigationMenuPrimitive.Root className="relative">
      <NavigationMenuPrimitive.List className="flex flex-row rounded-lg bg-white dark:bg-gray-800 p-2 space-x-2">
        <NavigationMenuPrimitive.Item>
          <NavigationMenuPrimitive.Trigger
            className={clsx(
              'px-3 py-2 text-sm rounded-md hover:bg-gray-100 dark:hover:bg-gray-900',
              'text-sm font-medium text-gray-700 dark:text-gray-100',
              'focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'
            )}
          >
            <div className="flex items-center">
              {t('aboutEMS')} <IoMdArrowDropdown size={10} className="ml-1" />
            </div>
          </NavigationMenuPrimitive.Trigger>

          <NavigationMenuPrimitive.Content
            className={clsx(
              'absolute w-auto top-0 left-0 rounded-lg',
              'radix-motion-from-start:animate-enter-from-left',
              'radix-motion-from-end:animate-enter-from-right',
              'radix-motion-to-start:animate-exit-to-left',
              'radix-motion-to-end:animate-exit-to-right'
            )}
          >
            <div className="w-[16rem] lg:w-[18rem] p-3">
              <div className="w-full flex flex-col space-y-2">
                <NavigationMenuPrimitive.Link
                  className={clsx(
                    'w-full px-4 py-3 hover:bg-gray-100 dark:hover:bg-gray-900 rounded-md',
                    'focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'
                  )}
                  href="/aboutems"
                >
                  <span
                    className={`text-sm font-medium text-gray-900 dark:text-gray-100 ${
                      pathname === '/aboutems' ? 'text-main' : ''
                    }`}
                  >
                    {t('whatIsEMS')}
                  </span>
                </NavigationMenuPrimitive.Link>

                <NavigationMenuPrimitive.Link
                  className={clsx(
                    'w-full px-4 py-3 hover:bg-gray-100 dark:hover:bg-gray-900 rounded-md',
                    'focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'
                  )}
                  href="/howToEMS"
                >
                  <span
                    className={`text-sm font-medium text-gray-900 dark:text-gray-100 ${
                      pathname === '/howToEMS' ? 'text-main' : ''
                    }`}
                  >
                    {t('howWorksEMS')}
                  </span>
                </NavigationMenuPrimitive.Link>
                <NavigationMenuPrimitive.Link
                  className={clsx(
                    'w-full px-4 py-3 hover:bg-gray-100 dark:hover:bg-gray-900 rounded-md',
                    'focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'
                  )}
                  href="/researchResults"
                >
                  <span
                    className={`text-sm font-medium text-gray-900 dark:text-gray-100 ${
                      pathname === '/researchResults' ? 'text-main' : ''
                    }`}
                  >
                    {t('researchResults')}
                  </span>
                </NavigationMenuPrimitive.Link>
              </div>
            </div>
          </NavigationMenuPrimitive.Content>
        </NavigationMenuPrimitive.Item>

        <NavigationMenuPrimitive.Item asChild>
          <NavigationMenuPrimitive.Link
            href="/results"
            className={clsx(
              'px-3 py-2 text-sm rounded-md hover:bg-gray-100 dark:hover:bg-gray-900',
              `text-sm font-medium text-gray-700 dark:text-gray-100 ${
                pathname === '/results' ? 'text-main' : ''
              }`
            )}
          >
            {t('results')}
          </NavigationMenuPrimitive.Link>
        </NavigationMenuPrimitive.Item>
        <NavigationMenuPrimitive.Item asChild>
          <NavigationMenuPrimitive.Link
            href="/photosandvideos"
            className={clsx(
              'px-3 py-2 text-sm rounded-md hover:bg-gray-100 dark:hover:bg-gray-900',
              `text-sm font-medium text-gray-700 dark:text-gray-100 ${
                pathname === '/photosandvideos' ? 'text-main' : ''
              }`
            )}
          >
            {t('photoAndVideo')}
          </NavigationMenuPrimitive.Link>
        </NavigationMenuPrimitive.Item>
        <NavigationMenuPrimitive.Item asChild>
          <NavigationMenuPrimitive.Link
            href="/faq"
            className={clsx(
              'px-3 py-2 text-sm rounded-md hover:bg-gray-100 dark:hover:bg-gray-900',
              `text-sm font-medium text-gray-700 dark:text-gray-100 ${
                pathname === '/faq' ? 'text-main' : ''
              }`
            )}
          >
            {t('questionsAndAnswers')}
          </NavigationMenuPrimitive.Link>
        </NavigationMenuPrimitive.Item>

        {/* <NavigationMenuPrimitive.Item asChild>
          <NavigationMenuPrimitive.Link
            href="/pricesems"
            className={clsx(
              'px-3 py-2 text-sm rounded-md hover:bg-gray-100 dark:hover:bg-gray-900',
              `text-sm font-medium text-gray-700 dark:text-gray-100 ${
                pathname === '/pricesems' ? 'text-main' : ''
              }`
            )}
          >
            {t('prices')}
          </NavigationMenuPrimitive.Link>
        </NavigationMenuPrimitive.Item> */}

        <NavigationMenuPrimitive.Item asChild>
          <NavigationMenuPrimitive.Link
            href="/contact"
            className={clsx(
              'px-3 py-2 text-sm rounded-md hover:bg-gray-100 dark:hover:bg-gray-900',
              `text-sm font-medium text-gray-700 dark:text-gray-100 ${
                pathname === '/contact' ? 'text-main' : ''
              }`
            )}
          >
            {t('contacts')}
          </NavigationMenuPrimitive.Link>
        </NavigationMenuPrimitive.Item>

        <NavigationMenuPrimitive.Indicator
          className={clsx(
            'z-10',
            'top-[100%] flex items-end justify-center h-2 overflow-hidden',
            'radix-state-visible:animate-fade-in',
            'radix-state-hidden:animate-fade-out',
            'transition-[width_transform] duration-[250ms] ease-[ease]'
          )}
        >
          <div className="top-1 relative bg-white dark:bg-gray-800 w-2 h-2 rotate-45" />
        </NavigationMenuPrimitive.Indicator>
      </NavigationMenuPrimitive.List>

      <div
        className="absolute left-[-40%] top-full flex w-full justify-center z-50"
        style={{
          perspective: '2000px',
        }}
      >
        <NavigationMenuPrimitive.Viewport
          className={clsx(
            'relative mt-2 shadow-lg rounded-md bg-white dark:bg-gray-800 overflow-hidden',
            'w-radix-navigation-menu-viewport',
            'h-radix-navigation-menu-viewport',
            'radix-state-open:animate-scale-in-content',
            'radix-state-closed:animate-scale-out-content',
            'origin-[top_center] transition-[width_height] duration-300 ease-[ease]'
          )}
        />
      </div>
    </NavigationMenuPrimitive.Root>
  );
};

export { NavigationMenu };
