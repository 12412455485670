import React from 'react';
import TextPropsComponent from './TextPropsComponent';
import { useTranslation } from 'react-i18next';

function SectionSixResearch() {
  const { t } = useTranslation();
  return (
    <div className="bg-white px-2 lg:px-20 py-16">
      <div className="textWrapper w-[100%] mb-32 px-2 lg:px-36 flex flex-col justify-center items-center">
        <h2 className="text-[30px] lg:text-[40px] font-bold text-center mb-10">
          {t('researchResultsSectionSixHeader')}
        </h2>
      </div>

      <div className="propsWrapper flex flex-col lg:flex-row justify-between">
        <TextPropsComponent
          propsFontSize="lg:text-[22px] xl:text-[26px] text-[26px]"
          heightProps="h-18 "
          propsMargin="mb-8 lg:mb-0"
          propsHeaderTitle="researchResultsSectionSixCardOneHeader"
          propsParagraphTitle="researchResultsSectionSixCardOneParagraph"
        />
        <TextPropsComponent
          propsFontSize="lg:text-[22px] xl:text-[26px] text-[26px]"
          heightProps="h-18"
          propsMargin="mb-8 lg:mb-0"
          propsHeaderTitle="researchResultsSectionSixCardTwoHeader"
          propsParagraphTitle="researchResultsSectionSixCardTwoParagraph"
        />
        <TextPropsComponent
          propsFontSize="lg:text-[22px] xl:text-[26px] text-[26px]"
          heightProps="h-18"
          propsMargin="mb-8 lg:mb-0"
          propsHeaderTitle="researchResultsSectionSixCardThreeHeader"
          propsParagraphTitle="researchResultsSectionSixCardThreeParagraph"
        />
        <TextPropsComponent
          propsFontSize="lg:text-[22px] xl:text-[26px] text-[26px]"
          heightProps="h-18"
          propsHeaderTitle="researchResultsSectionSixCardFourHeader"
          propsParagraphTitle="researchResultsSectionSixCardFourParagraph"
        />
      </div>
    </div>
  );
}

export default SectionSixResearch;
