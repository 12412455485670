import React, { useState } from "react";

function BetaTestPopUp() {

  const [isVisible, setIsVisible] = useState(true);

  const handleClick = () => {
    // Toggle the visibility when the button is clicked
    setIsVisible(!isVisible);
  };

  return (
    isVisible &&
    <div className="bg-[rgba(95,235,144,0)]  backdrop-blur-sm  border-solid border-[rgba(225,225,225,)] z-50 fixed h-[100%] w-[100%] flex items-center justify-center text-center text-white">
      <p className="text-[20px] flex flex-col justify-center items-center bg-[rgba(155,165,146,0.9)] h-[50%] w-[96%] rounded-xl backdrop-blur-sm border-[2px] border-solid border-[rgba(225,225,225,)] p-6">
      <span className="mb-6">
      🚀 Beta Test in Progress! Some features may be unstable, and text/media content could differ from the final version. We apologize for any inconvenience!
      </span>
      <button onClick={handleClick} className="border-[3px] rounded bg-[#6e7068] hover:bg-[#fe751af0] duration-[10ms]  border-main py-2 px-10 bg-transparent text-main font-bold transition-all ease-in">
        Close
      </button>
      
      </p>
      
    </div>
  );
}

export default BetaTestPopUp;
