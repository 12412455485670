import React from 'react';
import TextPropsComponent from './TextPropsComponent';
import { useTranslation } from 'react-i18next';

function SectionThreeResearch() {
  const { t } = useTranslation();
  return (
    <div className="bg-white px-2 lg:px-20 py-16">
      <div className="textWrapper w-[100%] mb-32 px-2 lg:px-36 flex flex-col justify-center items-center">
        <h2 className="text-[30px] lg:text-[40px] font-bold text-center mb-10">
          {t('researchResultsSectionThreeHeader')}
        </h2>
        <p className="text-[20px] font-thin text-center lg:w-[60%]">
          {t('researchResultsSectionThreeParagraph')}
        </p>
      </div>

      <div className="propsWrapper flex flex-col lg:flex-row justify-between">
        <TextPropsComponent
          propsFontSize="lg:text-[22px] xl:text-[26px] text-[26px]"
          heightProps="h-18 "
          propsMargin="mb-8 lg:mb-0"
          propsHeaderTitle="researchResultsSectionThreeCardOneHeader"
          propsParagraphTitle="researchResultsSectionThreeCardOneParagraph"
        />
        <TextPropsComponent
          propsFontSize="lg:text-[22px] xl:text-[26px] text-[26px]"
          heightProps="h-18"
          propsMargin="mb-8 lg:mb-0"
          propsHeaderTitle="researchResultsSectionThreeCardTwoHeader"
          propsParagraphTitle="researchResultsSectionThreeCardTwoParagraph"
        />
        <TextPropsComponent
          propsFontSize="lg:text-[22px] xl:text-[26px] text-[26px]"
          heightProps="h-18"
          propsMargin="mb-8 lg:mb-0"
          propsHeaderTitle="researchResultsSectionThreeCardThreeHeader"
          propsParagraphTitle="researchResultsSectionThreeCardThreeParagraph"
        />
        <TextPropsComponent
          propsFontSize="lg:text-[22px] xl:text-[26px] text-[26px]"
          heightProps="h-18"
          propsHeaderTitle="researchResultsSectionThreeCardFourHeader"
          propsParagraphTitle="researchResultsSectionThreeCardFourParagraph"
        />
      </div>
    </div>
  );
}

export default SectionThreeResearch;
