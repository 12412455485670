import React from 'react';
import Ass from '../Assets/ASS.jpg';
import GridCards from './GridCards';
import cardOne from '../Assets/cardOne.jpg';
import cardEight from '../Assets/cardEight.jpg';
import cardThree from '../Assets/cardThree.jpg';
import cardFour from '../Assets/cardFour.jpg';
import cardFive from '../Assets/cardFive.jpg';
import cardSix from '../Assets/cardSix.jpg';
import cardSeven from '../Assets/cardSeven.jpg';
import { useTranslation } from 'react-i18next';

function GridCardSection() {
  const { t } = useTranslation();
  return (
    <section className="w-[100%] bg-white flex flex-col items-center py-24">
      <h2 className="text-[40px] text-center font-bold">
        {t('gridCardSectionHeader')}
      </h2>
      <ul className="iconsWrapper grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-16 gap-x-8 mt-32">
        <GridCards
          imgSrc={cardOne}
          imgAlt="businessman"
          textHeader="gridCardHeaderOne"
          textPar="gridCardPartOne"
        />
        <GridCards
          imgSrc={Ass}
          imgAlt="beautiful ass"
          textHeader="gridCardHeaderTwo"
          textPar="gridCardPartTwo"
        />
        <GridCards
          imgSrc={cardThree}
          imgAlt="fat woman"
          textHeader="gridCardHeaderThree"
          textPar="gridCardPartThree"
        />
        <GridCards
          imgSrc={cardFour}
          imgAlt="thinking man"
          textHeader="gridCardHeaderFour"
          textPar="gridCardPartFour"
        />
        <GridCards
          imgSrc={cardFive}
          imgAlt="financial tragedy"
          textHeader="gridCardHeaderFive"
          textPar="gridCardPartFour"
        />
        <GridCards
          imgSrc={cardSix}
          imgAlt="swimmer"
          textHeader="gridCardHeaderSix"
          textPar="gridCardPartFive"
        />
        <GridCards
          imgSrc={cardSeven}
          imgAlt="back pain"
          textHeader="gridCardHeaderSeven"
          textPar="gridCardPartSix"
        />
        <GridCards
          imgSrc={cardEight}
          imgAlt="old couple"
          textHeader="gridCardHeaderEight"
          textPar="gridCardPartSeven"
        />
      </ul>
    </section>
  );
}

export default GridCardSection;
