import React from 'react';
import HowToEMSPropsComponent from './HowToEMSPropsComponent';
import { useTranslation } from 'react-i18next';

function SectionThreeEMS() {
  const { t } = useTranslation();
  return (
    <div className="py-36 px-10 flex flex-col justify-center items-center">
      <h2 className="text-[38px] font-bold text-center mb-20">
        {t('howWorksSectionTwoHeader')}
      </h2>
      <div className="divWrapper">
        <HowToEMSPropsComponent
          flexDirectionProp="lg:flex-row flex-row-reverse"
          textDirectionProp="text-start lg:text-end"
          circleNumber="1"
          textTitle="howWorksBulletHeaderOne"
          textParagraph="howWorksBulletParagraphOne"
        />
        <HowToEMSPropsComponent
          flexDirectionProp="flex-row-reverse"
          circleNumber="2"
          textTitle="howWorksBulletHeaderTwo"
          textParagraph="howWorksBulletParagraphTwo"
        />
        <HowToEMSPropsComponent
          flexDirectionProp="lg:flex-row flex-row-reverse"
          textDirectionProp="text-start lg:text-end"
          circleNumber="3"
          textTitle="howWorksBulletHeaderThree"
          textParagraph="howWorksBulletParagraphThree"
        />
        <HowToEMSPropsComponent
          flexDirectionProp="flex-row-reverse"
          circleNumber="4"
          textTitle="howWorksBulletHeaderFour"
          textParagraph="howWorksBulletParagraphFour"
        />
        <HowToEMSPropsComponent
          flexDirectionProp="lg:flex-row flex-row-reverse"
          textDirectionProp="text-start lg:text-end"
          circleNumber="5"
          textTitle="howWorksBulletHeaderFive"
          textParagraph="howWorksBulletParagraphFive"
        />
      </div>
    </div>
  );
}

export default SectionThreeEMS;
