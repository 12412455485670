import React from 'react';
import clsx from 'clsx';
import * as Accordion from '@radix-ui/react-accordion';
import { BsChevronDown } from 'react-icons/bs';

const AccordionDemo = ({ children }) => {
  return (
    <Accordion.Root className="w-full rounded-md " type="single" collapsible>
      {children}
    </Accordion.Root>
  );
};

export const AccordionItem = React.forwardRef(
  ({ children, className, margin, ...props }, forwardedRef) => (
    <Accordion.Item
      className={clsx(
        `overflow-hidden first:mt-0 focus-within:relative focus-within:z-10 ${margin}`,
        className
      )}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </Accordion.Item>
  )
);

export const AccordionTrigger = React.forwardRef(
  (
    {
      children,
      className,
      backgroundColor,
      fontWeight,
      py,
      iconColor,
      iconSize,
      ...props
    },
    forwardedRef
  ) => (
    <Accordion.Header className="flex">
      <Accordion.Trigger
        className={clsx(
          `group flex h-[45px] flex-1 cursor-pointer items-center ${fontWeight} justify-between ${backgroundColor} px-5 ${py} text-[15px] leading-none  outline-none `,
          className
        )}
        {...props}
        ref={forwardedRef}
      >
        {children}
        <BsChevronDown
          size={iconSize}
          className={`ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform duration-300 group-data-[state=open]:rotate-180 ${iconColor}`}
          aria-hidden
        />
      </Accordion.Trigger>
    </Accordion.Header>
  )
);

export const AccordionContent = React.forwardRef(
  ({ children, className, margin, ...props }, forwardedRef) => (
    <Accordion.Content
      className={clsx(
        `${margin} data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden text-[15px]`,
        className
      )}
      {...props}
      ref={forwardedRef}
    >
      <div>{children}</div>
    </Accordion.Content>
  )
);

export default AccordionDemo;
