import ArrowUp from '../components/ArrowUp';
import { Header } from '../components/Header';
import Hero from '../components/Hero';
import { VideoSection } from '../components/VideoSection';
import { ContactFormSection } from '../components/ContactFormSection';
import OrangeCardSection from '../components/OrangeCardSection';
import GridCardSection from '../components/GridCardSection';
import FooterSection from '../components/FooterSection';
import ResultsSection from '../components/ResultsSection';
import EmsSection from '../components/EmsSection';
import FloatingLink from '../components/FloatingLink';
import CodeLuminis from '../components/CodeLuminis';
import BetaTestPopUp from '../components/BetaTestPopUp';
import BetaTest from '../components/BetaTest';

export const Main = () => {
  return (
    <div className="h-[200vh]">
      <BetaTestPopUp />
      <BetaTest />
      <FloatingLink />
      <Header />
      <ArrowUp />
      <Hero
        backgroundHeight="h-[400px]"
        backgroundPosition="bg-left"
        backgroundImage="bg-sectionOne"
        backgroundOverlay="bg-[rgb(61,54,5,0.7)]"
        headerTitle="smallTimeTitle"
        secondaryTitle="smallTimeSpan"
        isAnimated={true}
        arrowOn={true}
      />
      <OrangeCardSection />
      <VideoSection
        header="videoSectionHeader"
        paragraph="videoSectionParagraph"
        buttonText="aboutEMSHeader"
        buttonLink="/aboutEms"
      />
      <GridCardSection />
      <ContactFormSection />
      <EmsSection />
      <ResultsSection />
      <FooterSection />
      <CodeLuminis />
    </div>
  );
};
