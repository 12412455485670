import { Header } from "../components/Header";
import FooterSection from "../components/FooterSection";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
const ThankYou = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="h-[600px] bg-thankYouImage bg-cover bg-no-repeat ">
        <div className="overlay bg-[rgb(0,0,0,0.60)] p-24 w-[100%] h-[100%] flex flex-col items-center justify-center">
          <div className="thankYouWrapper md:w-[500px] md:h-[500px] lg:w-[600px] lg:h-[600px] w-[300px] h-[300px]  flex flex-col justify-center text-center">
            <h1 className="text-white mb-8  text-[30px] md:text-[38px] lg:text-[48px] font-bold text-center">
              {t("thankYouHeader")}!
            </h1>
            <p className="text-white text-[16px] md:text-[18px] lg:text-[24px] text-center">
              {t("thankYouParagraph")}
            </p>
          </div>
          <button
            onClick={() => navigate(-1)}
            className="py-1 px-10 border-solid border-[2.5px] border-[#b3d235] text-[#b3d235] text-[25px] font-roboto  cursor-pointer hover:text-white hover:border-[#fe7419] hover:bg-[#fe7419] rounded-[2%] font-bold transition-all duration-300"
          >
            {t("goBack")}
          </button>
        </div>
      </div>
      <FooterSection />
    </>
  );
};

export default ThankYou;
