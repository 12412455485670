import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaPaperPlane } from 'react-icons/fa';
import { FaFacebookF } from 'react-icons/fa';
import { AiFillInstagram, AiOutlineCheckCircle } from 'react-icons/ai';

import { useTranslation } from 'react-i18next';

function FooterSection() {
  const { t } = useTranslation();

  const [formIsSubmitted, setIsFormSubmitted] = useState(false);

  const handleSubmit = (event) => {
    // Popup to confirm send the email
    event.preventDefault();

    setIsFormSubmitted(true);
  };
  return (
    <div className="bg-[#454545] py-6 px-24">
      <div className="footerWrapper grid gap-y-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-4 justify-items-center md:text-left">
        <div className="leftListWrapper h-[100%] w-[250px] flex flex-col justify-between">
          <div className="unorderedListWrapper">
            <h2 className="font-bold text-[16px] text-gray-400">
              {t('aboutEMS')}
            </h2>
            <ul className="text-white mt-6">
              <li className="mb-3 text-[12px]">
                <Link to="/aboutEms">{t('whatIsEMS')}</Link>
              </li>
              <li className="mb-3 text-[12px]">
                <Link to="/howToEMS">{t('howWorksEMS')}</Link>
              </li>
              <li className="mb-3 text-[12px]">
                <Link to="/researchResults">{t('researchResults')}</Link>
              </li>
              <li className="mb-3 text-[12px]">
                <Link to="/results">{t('results')}</Link>
              </li>
              <li className="text-[12px]">
                <Link to="/faq">{t('questionsAndAnswers')}</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="h-[100%] w-[250px]">
          <h2 className="font-bold text-[16px] text-gray-400">{t('studio')}</h2>
          <ul className="text-white mt-6">
            <li className="mb-2 text-[12px]">
              <Link to="/photosAndVideos">{t('photoAndVideo')}</Link>
            </li>
            <li className="mb-2 text-[12px]">
              <Link to="/contact">{t('contacts')}</Link>
            </li>
          </ul>
        </div>
        <div className="h-[100%] w-[300px]">
          <h2 className="font-bold text-[16px] text-gray-400 mb-3">
            {t('subscribe')}
          </h2>
          {formIsSubmitted ? (
            <div className="flex text-white items-center">
              <AiOutlineCheckCircle className="mr-4" size={40} />
              <p>{t('thankYouNewsletter')}</p>
            </div>
          ) : (
            <form
              className="emailWrapper flex flex-row"
              onSubmit={handleSubmit}
            >
              <input
                type="email"
                placeholder={t('emailPlaceholder')}
                className="p-3 text-black w-[150px] md:w-[250px] mr-4 rounded-md"
              />
              <button
                aria-label="Newsletter Form Submit Button"
                title="Newsletter Form Submit Button"
                type="submit"
                className="bg-[#b3d235] w-[60px] h-[50px] flex justify-center items-center text-white hover:text-black transition-all duration-[400ms]"
              >
                <FaPaperPlane size={20} />
              </button>
            </form>
          )}
          <div className="socialWrapper mt-6">
            <ul className="social flex flex-row">
              <li className="facebook rounded-[100%] mr-1 h-[25px] w-[25px] bg-main flex items-center justify-center">
                <a
                  href="https://www.facebook.com/justfit.ge"
                  rel="noreferrer"
                  target="_blank"
                  className="h-[20px] w-[20px] text-[#454545] flex justify-center items-center text-center"
                >
                  <FaFacebookF size={13} />
                </a>
              </li>

              <li className="instagram rounded-[100%] h-[25px] w-[25px] bg-main flex items-center justify-center">
                <a
                  href="https://www.instagram.com/justfit.ge"
                  rel="noreferrer"
                  target="_blank"
                  className="h-[20px] w-[20px] text-[#454545] flex justify-center items-center text-center"
                >
                  <AiFillInstagram size={18} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p className="text-white text-[12px] mt-16">
        © 2023 JustFit EMS fitness studio
      </p>
    </div>
  );
}

export default FooterSection;
