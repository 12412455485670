import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SlArrowDown } from 'react-icons/sl';
import { useLocation } from 'react-router-dom';
import Typed from 'react-typed';

function Hero({
  headerTitle,
  backgroundPosition,
  backgroundSize,
  backgroundOverlay,
  backgroundImage,
  backgroundHeight,
  secondHeaderTitle,
  paragraphTitle,
  secondaryTitle,
  isAnimated,
  arrowOn,
}) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [resetKey, setResetKey] = useState(0);

  useEffect(() => {
    const handleLanguageChange = () => {
      // Increment the key to reset Typed when language changes
      setResetKey(resetKey + 1);
    };

    // Listen for language changes
    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      // Remove the language change listener when the component unmounts
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n, resetKey]);

  return (
    <div
      className={`${
        backgroundImage ? backgroundImage : 'bg-gray-500 p-8'
      } bg-fixed   ${backgroundSize} ${backgroundPosition} bg-no-repeat flex justify-center flex-col items-center relative`}
    >
      <div
        className={`${backgroundOverlay} w-[100%] flex justify-center items-center`}
      >
        <div
          className={`${
            isAnimated ? 'md:mr-[300px]' : 'md:mr-[0px]'
          } max-w-[1200px] md:w-[810px] ${backgroundHeight} flex flex-col justify-center items-center px-5`}
        >
          <h1 className="w-[100%] text-[30px] md:text-[46px] leading-[45px] md:leading-[50px] font-bold text-[#b3d235] ">
            {t(headerTitle)}
            {isAnimated ? (
              <Typed
                className="text-white ml-4"
                strings={[t(secondaryTitle)]}
                typeSpeed={60}
                backSpeed={70}
                loop
                key={resetKey}
              />
            ) : (
              <div className="text-white flex flex-col justify-center items-center">
                <h2
                  className={`${
                    backgroundImage ? 'mb-10' : ''
                  } text-[50px] text-center font-bold leading-tight`}
                >
                  {t(secondHeaderTitle)}
                </h2>
                <p className="font-thin text-center leading-8 text-[25px]">
                  {t(paragraphTitle)}
                </p>
              </div>
            )}
          </h1>
        </div>
      </div>
      <div className="Arrow absolute mt-[330px] text-white">
        <a
          className="cursor-pointer"
          href={`${location.pathname}#justFitStudio`}
        >
          {arrowOn ? <SlArrowDown size={40} className="animate-upDown" /> : ''}
        </a>
      </div>
    </div>
  );
}

export default Hero;
