import FooterSection from '../../components/FooterSection';
import { Header } from '../../components/Header';
import FloatingLink from '../../components/FloatingLink';
import ArrowUp from '../../components/ArrowUp';
import React from 'react';
import Hero from '../../components/Hero';
import PhotosSectionTwo from './PhotosSectionTwo';
import PhotosSectionThree from './PhotosSectionThree';
import GridSection from './GridSection';
import CodeLuminis from '../../components/CodeLuminis';
import BetaTest from '../../components/BetaTest';
function PhotosAndVideos() {
  return (
    <>
      <BetaTest />
      <FloatingLink />
      <Header />
      <ArrowUp />
      <Hero
        backgroundHeight="h-[500px]"
        backgroundPosition="bg-[center_top_0rem]"
        backgroundImage="bg-jackets"
        backgroundOverlay="bg-[rgb(0,0,0,0.5)]"
        isAnimated={false}
        arrowOn={true}
        paragraphTitle="photoesAndVideosParagraph"
        secondHeaderTitle="photoAndVideo"
      />
      <PhotosSectionTwo />
      <PhotosSectionThree />
      <GridSection />
      <FooterSection />
      <CodeLuminis />
    </>
  );
}

export default PhotosAndVideos;
