import React from 'react';
import EmsimageOne from '../../Assets/emsSectionThreeImageOne.jpg';
import EmsPropsFile from './EmsPropsFile';
import { useTranslation } from 'react-i18next';

function EmsSectionThree() {
  const { t } = useTranslation();
  return (
    <div className="bg-[#eeeeee]  w-[100%] p-5">
      <div className="headerWrapper w-[100%] text-center">
        <h2 className="text-[30px] font-bold ">
          {t('aboutEMSSectionThreeHeader')}
        </h2>
      </div>
      <div className="imageWrapper w-[100%] my-10 flex flex-col lg:flex-row items-center justify-center ">
        <img
          className="lg:mr-4 md:h-[470px] lg:h-[360px] xl:h-[460px] md:w-[600px] lg:w-[460px] xl:w-[560px]"
          src={EmsimageOne}
          alt="emsImageOne"
        />

        <div className="propsWrapper mt-10 lg:mt-0 lg:ml-4 md:w-[600px] flex flex-col items-center justify-center">
          <div>
            <EmsPropsFile
              emsLogoImage="bg-emsLogoOne"
              emsHeaderTitle="aboutEMSSectionThreeTitleOne"
              emsParagraphTitle="aboutEMSSectionThreeSecondaryTitleOne"
            />
          </div>
          <div className="my-8">
            <EmsPropsFile
              emsLogoImage="bg-emsLogoTwo"
              emsHeaderTitle="aboutEMSSectionThreeTitleTwo"
              emsParagraphTitle="aboutEMSSectionThreeSecondaryTitleTwo"
            />
          </div>
          <div>
            <EmsPropsFile
              emsLogoImage="bg-emsLogoThree"
              emsHeaderTitle="aboutEMSSectionThreeTitleThree"
              emsParagraphTitle="aboutEMSSectionThreeSecondaryTitleThree"
            />
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default EmsSectionThree;
