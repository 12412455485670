import React from "react";
import { useSwiper } from "swiper/react";

const Image = ({ item }) => {
  const swiper = useSwiper();

  return (
    <div
      className={`${item.backgroundImage} w-[500px] h-[500px] bg-center bg-[size:100%]  bg-no-repeat`}
      onClick={() => swiper.slideNext()}
    ></div>
  );
};

export default Image;
