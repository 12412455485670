import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function FloatingLink() {
  const { t } = useTranslation();
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 900) {
        if (!document.getElementById('floatingLink')) return;
        document.getElementById('floatingLink').style.visibility = 'visible';
        document.getElementById('floatingLink').style.opacity = '1';
        document.getElementById('floatingLink').style.cursor = 'pointer';
      } else {
        if (!document.getElementById('floatingLink')) return;
        document.getElementById('floatingLink').style.visibility = 'hidden';
        document.getElementById('floatingLink').style.opacity = '0';
        document.getElementById('floatingLink').style.cursor = 'default';
      }
    });
  }, []);

  return (
    <Link
      to="/#contactFormSection"
      id="floatingLink"
      className="invisible opacity-0 z-40 text-white flex justify-center items-center font-bold  hover:bg-secondary duration-[300ms] ease-in-out h-[30px] lg:h-[30px]  bg-[#b3d235] fixed ml-[5%] mt-[2%] rounded-lg text-center px-4"
    >
      <div>{t('researchResultsVideoSectionButtonText')}</div>
    </Link>
  );
}

export default FloatingLink;

// color choice green - #b3d235 orange - #fe7419
