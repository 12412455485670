import React from 'react';
import { useTranslation } from 'react-i18next';

function TextPropsComponent({
  propsHeaderTitle,
  propsParagraphTitle,
  propsMargin,
  propsFontSize,
  heightProps,
}) {
  const { t } = useTranslation();
  return (
    <div className={`${propsMargin} lg:w-[30%] xl:w-[20%] mx-5`}>
      <h3
        className={`${propsFontSize} ${heightProps}  font-bold text-[#fe751a] border-b-2 border-[#fe751a] border-solid mb-4`}
      >
        {t(propsHeaderTitle)}
      </h3>
      <p className="lg:text-[16px] xl:text-[18px] text-[18px] font-thin">
        {t(propsParagraphTitle)}
      </p>
    </div>
  );
}

export default TextPropsComponent;
