import ArrowUp from '../../components/ArrowUp';
import FloatingLink from '../../components/FloatingLink';
import FooterSection from '../../components/FooterSection';
import { Header } from '../../components/Header';
import React, { useEffect, useState } from 'react';
import Hero from '../../components/Hero';
import SectionFourEMS from '../howToEms/SectionFourEMS';
import SectionTwoResearch from './SectionTwoResearch';
import SectionThreeResearch from './SectionThreeResearch';
import SectionSixResearch from './SectionSixResearch';
import ResearchCardSection from './ResearchCardSection';
import { VideoSection } from '../../components/VideoSection';
import CodeLuminis from '../../components/CodeLuminis';
import BetaTest from '../../components/BetaTest';

function ResearchResults() {
  const [isInView, setisInView] = useState(window.innerWidth > 1024);

  const updateMedia = () => {
    setisInView(window.innerWidth > 1024);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <>
      <BetaTest />
      <FloatingLink />
      <Header />
      <ArrowUp />
      {isInView ? (
        <Hero
          backgroundImage="bg-[#eeeeee] lg:bg-researchHeroBackground"
          backgroundHeight="h-[500px]"
          backgroundSize="bg-cover bg-local xl:bg-fixed"
          backgroundOverlay="lg:bg-[rgb(0,0,0,0.5)]"
          isAnimated={false}
          arrowOn={true}
          secondHeaderTitle="researchResults"
          paragraphTitle="researchResultsParagraph"
        />
      ) : (
        false
      )}
      <SectionTwoResearch />
      <SectionThreeResearch />
      <VideoSection
        header="researchResultsVideoSectionHeader"
        paragraph="researchResultsVideoSectionParagraph"
        buttonText="researchResultsVideoSectionButtonText"
        buttonLink="/#contactForm"
      />
      <ResearchCardSection />
      <SectionSixResearch />

      <SectionFourEMS
        titleProps="researchResultsSectionEightHeader"
        paragraphProps="researchResultsSectionEightParagraph"
      />
      <FooterSection />
      <CodeLuminis />
    </>
  );
}

export default ResearchResults;
