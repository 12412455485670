import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function ButtonPropsSection({
  buttonParagraph,
  buttonProps,
  buttonBackground,
  buttonLink,
}) {
  const { t } = useTranslation();
  return (
    <div className={`bg-${buttonBackground} p-10 w-[100%]`}>
      <div className="wrapperMain w-[100%] flex flex-col lg:flex-row justify-center items-center">
        <p className="text-[18px] md:text-[20px] lg:text-[22px] font-thin mb-4 md:w-[35%] lg:w-[40%] md:mr-6">
          {t(buttonParagraph)}
        </p>
        <Link
          to={buttonLink}
          className="md:ml-6 w-[340px] h-[60px] border-solid border-[2.5px] border-[#b3d235] text-[#b3d235] hover:text-white hover:border-none hover:bg-[#fe7419] rounded-[2%] font-bold transition-all duration-300 flex justify-center items-center cursor-pointer"
        >
          {t(buttonProps)}
        </Link>
      </div>
    </div>
  );
}

export default ButtonPropsSection;
