import React from 'react';
import JustfitEms from '../Assets/JustfitEMS.png';
import Skribbl from '../Assets/skribbl.png';
import Waist from '../Assets/waist.png';
import Note from '../Assets/note.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function EmsSection() {
  const { t } = useTranslation();
  return (
    <section className="w-[100%] p-12">
      <h2 className="text-center text-[40px] font-bold text-black mb-12">
        {t('emsAdvantages')}
      </h2>
      <div className="contentWrapper w-[100%] flex flex-col items-center lg:flex-row">
        <div className="divLeft w-[100%] lg:w-[50%] flex justify-center mt-16">
          <div className="divLeftWrapper lg:w-[70%] h-[100%] flex flex-col">
            <h3 className="text-secondary font-bold mb-6 text-xl">
              {t('emsAdvantagesHeaderOne')}
            </h3>
            <div className="flex items-center mb-24">
              <p className=" w-[80%]">{t('emsAdvantagesParagraphOne')}</p>
              <img src={Waist} alt="waist" className="h-[70px] w-[70px]" />
            </div>
            <h3 className="text-secondary font-bold mb-6 text-xl">
              {t('emsAdvantagesHeaderTwo')}
            </h3>
            <div className="flex items-center mb-24">
              <p className="w-[80%]">{t('emsAdvantagesParagraphTwo')}</p>
              <img src={Skribbl} alt="skribbl" className="h-[70px] w-[70px]" />
            </div>
            <h3 className="text-secondary font-bold mb-6 text-xl">
              {t('emsAdvantagesHeaderThree')}
            </h3>
            <div className="flex items-center">
              <p className="w-[80%]">{t('emsAdvantagesParagraphThree')}</p>
              <img src={Note} alt="note" className="h-[70px] w-[70px]" />
            </div>

            <Link
              to={t('researchResultsDocLink')}
              target="_blank"
              className="w-[100%] h-[60px] mt-14 border-solid border-[2.5px] border-[#b3d235] text-[#b3d235] flex justify-center items-center cursor-pointer hover:text-white hover:border-none hover:bg-[#fe7419] rounded-[2%] font-bold transition-all duration-300"
            >
              {t('aboutEmsResults')}
            </Link>
          </div>
        </div>
        <div className="divRight mt-10 lg:mt-0 flex items-center justify-center w-[50%]">
          <img src={JustfitEms} alt="EMS" />
        </div>
      </div>
    </section>
  );
}

export default EmsSection;
