import React from 'react';
import { Link } from 'react-router-dom';
import codeLuminisLogoGrey from '../Assets/CodeLuminisLogoGrey.png';
import CodeLuminisLogoBlack from '../Assets/CodeLuminisLogoBlack.png';
function CodeLuminis() {
  return (
    <Link to="https://www.codeluminis.com" target="_blank">
      <div className="py-3 flex items-center justify-center text-[14px] font-thin text-[#9a9797df] hover:text-black group ">
        Made By
        <span className="flex flex-row relative">
          <img
            src={codeLuminisLogoGrey}
            alt="CodeLuminisLogo"
            className="h-[60px]"
          />
          <img
            src={CodeLuminisLogoBlack}
            alt="CodeLuminisLogoDarkened"
            className="h-[60px] absolute z-[-1] group-hover:z-0"
          />
        </span>
        <span>Code Luminis</span>
      </div>
    </Link>
  );
}
export default CodeLuminis;
