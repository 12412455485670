import { FaFacebookF } from 'react-icons/fa';
import FooterSection from '../components/FooterSection';
import { Header } from '../components/Header';
import Hero from '../components/Hero';
import { AiFillInstagram } from 'react-icons/ai';
import CodeLuminis from '../components/CodeLuminis';
import BetaTest from '../components/BetaTest';

export const Contact = () => {
  return (
    <>
      <BetaTest />
      <Header />
      <Hero arrowOn={false} secondHeaderTitle="contactHeader" />
      <section className="bg-gray-200">
        <div className="max-w-[1200px] flex justify-center items-center mx-auto p-12 flex-col md:flex-row">
          <div className="text-center md:text-left">
            <h3 className="text-secondary text-2xl font-bold mb-4">
              Justfit Georgia
            </h3>
            <p className="font-thin text-xl mb-4 ">
              Tbilisi, Ilia Chavchavadze Ave. 37m (Axis Business Center)
            </p>
            <a
              href="tel:+995 591 56 65 65"
              className="font-thin text-xl mb-4 block"
            >
              +995 591 56 65 65
            </a>
            <a
              href="mailto:Info@justfit.ge"
              className="font-thin text-xl mb-4 block"
            >
              Info@justfit.ge
            </a>

            <div className="socialWrapper my-6">
              <ul className="social flex justify-center items-center md:justify-start">
                <li className="facebook rounded-[100%] mr-1 h-[25px] w-[25px] bg-main flex items-center justify-center">
                  <a
                    href="https://www.facebook.com/justfit.ge"
                    rel="noreferrer"
                    target="_blank"
                    className="h-[20px] w-[20px] text-white flex justify-center items-center text-center"
                  >
                    <FaFacebookF size={13} />
                  </a>
                </li>

                <li className="instagram rounded-[100%] h-[25px] w-[25px] bg-main flex items-center justify-center">
                  <a
                    href="https://www.instagram.com/justfit.ge"
                    rel="noreferrer"
                    target="_blank"
                    className="h-[20px] w-[20px] text-white flex justify-center items-center text-center"
                  >
                    <AiFillInstagram size={18} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d285.0182541311134!2d44.75574919818399!3d41.71165313480714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x404473ccc057b21f%3A0xce3c92156f05a396!2z4YOQ4YOl4YOh4YOY4YOhIOGDl-GDkOGDo-GDlOGDoOGDoeGDmA!5e0!3m2!1ska!2sge!4v1696016973836!5m2!1ska!2sge"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              title="map"
              className="w-full h-[300px] md:h-[500px] b-none"
            ></iframe>
          </div>
        </div>
      </section>
      <FooterSection />
      <CodeLuminis />
    </>
  );
};
