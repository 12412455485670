import React from "react";
import { useTranslation } from "react-i18next";

function ResultsSectionFour() {
  const { t } = useTranslation();
  return (
    <div className="h-[500px] w-[100%] bg-[#989f37] text-white text-[25px] font-thin flex justify-center items-center text-center px-8 md:px-20 py-10">
      {t("emsResultsPageTraumasParagraph")}
    </div>
  );
}

export default ResultsSectionFour;
