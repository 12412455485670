import React from 'react';
import { useTranslation } from 'react-i18next';

function BackgroundGridComponent({
  backgroundGridImage,
  backgroundGridWidth,
  backgroundGridHeader,
  backgroundGridParagraph,
}) {
  const { t } = useTranslation();
  return (
    <div
      className={`${backgroundGridWidth} flex flex-col  items-center h-[390px]`}
    >
      <div
        className={`${backgroundGridImage} bg-cover h-[100px] w-[100px]`}
      ></div>
      <h3 className="text-[20px] text-center font-bold my-6">
        {t(backgroundGridHeader)}
      </h3>
      <p className="text-center text-[12px] xl:text-[15px] w-[85%] ">
        {t(backgroundGridParagraph)}
      </p>
    </div>
  );
}

export default BackgroundGridComponent;
