import React from "react";
import { useTranslation } from "react-i18next";

function GridCards({ imgSrc, imgAlt, textHeader, textPar }) {
  const { t } = useTranslation();
  return (
    <li className="w-[250px] flex flex-col items-center">
      <div className="h-[110px] w-[110px]">
        <img src={imgSrc} alt="imgAlt" className="rounded-[100%]" />
      </div>
      <h3 className="my-8 font-bold text-[18px] text-center">
        {t(textHeader)}
      </h3>
      <p className="text-center">{t(textPar)}</p>
    </li>
  );
}

export default GridCards;
