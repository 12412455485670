import React from 'react';
import { useTranslation } from 'react-i18next';

function HowToEMSPropsComponent({
  flexDirectionProp,
  textDirectionProp,
  textTitle,
  textParagraph,
  circleNumber,
}) {
  const { t } = useTranslation();
  return (
    <div className={`${flexDirectionProp} w-[100%] flex`}>
      <div className={`${textDirectionProp} textWrapper  w-[100%] lg:w-[50%]`}>
        <h3 className="text-[20px] font-bold px-14 mb-6">{t(textTitle)}</h3>
        <p className="text-[15px] font-thin  px-14 mb-6">{t(textParagraph)}</p>
      </div>
      <div className="flex flex-row justify-center relative">
        <div className="circleDiv absolute h-[50px] w-[50px] rounded-full bg-[#fe751a] text-white font-bold text-[20px] flex items-center justify-center">
          {circleNumber}
        </div>
        <div className="lineDiv h-[100%] w-[2px] bg-black mt-10"></div>
      </div>
    </div>
  );
}

export default HowToEMSPropsComponent;

//<div className="h-100% w-[2px] bg-black z-50"></div>
