import React from 'react';
import { useTranslation } from 'react-i18next';

function CardsPropsComponent({
  cardsThirdHeader,
  cardsParagraphMain,
  borderShadow,
  cardsBackground,
  cardsBackgroundSize,
  textBackground,
  textWrapperProp,
}) {
  const { t } = useTranslation();
  return (
    <div
      className={`${borderShadow}cardComponentWrapper h-[420px] w-[360px] bg-black relative `}
    >
      <div
        className={`${cardsBackground} ${cardsBackgroundSize} imageBackground h-[100%] w-[100%]  bg-no-repeat bg-top   `}
      ></div>
      <div
        className={`${textBackground} ${textWrapperProp} textCardWrapper p-7  h-[40%] w-[100%] absolute bottom-0`}
      >
        <h3 className="text-[24px] font-bold mb-4">{t(cardsThirdHeader)}</h3>
        <p className="text-[14px] font-thin">{t(cardsParagraphMain)}</p>
      </div>
    </div>
  );
}

export default CardsPropsComponent;
