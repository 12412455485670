import React from 'react';
import { useTranslation } from 'react-i18next';

function EmsSectionTwo() {
  const { t } = useTranslation();
  return (
    <div id="justFitStudio" className="flex justify-center items-center">
      <div className="grid gap-x-14 grid-cols-1 xl:grid-cols-2 my-14">
        <div className="textWrapper h-[500px] md:w-[600px] sm:w-[500px] w-[300px] flex flex-col ">
          <h2 className="md:text-[24px] sm:text-[24px] text-[18px] font-bold">
            <span className="text-[#ef9416]">15 {t('aboutEMSFirstSpan')} </span>{' '}
            EMS =
            <span className="text-[#ef9416]"> 2 {t('aboutEMSSecondSpan')}</span>{' '}
            {t('aboutEMSThirdSpan')}
          </h2>
          <p className="flex flex-col md:text-[21px] sm:text-[18px] text-[15px] font-thin mb-8">
            {t('aboutEMSFirstParagraph')}
            <span className="mt-9">{t('aboutEMSFourthSpan')}</span>
          </p>
        </div>
        <div className="videoWrapper  h-[300px] md:w-[500px] sm:w-[500px] w-[300px] ">
          <iframe
            className=" md:h-[300px] h-[200px] md:w-[500px] sm:w-[500px] w-[300px]"
            src="https://www.youtube.com/embed/5JHTOytoFZA"
            title="youTubeVideo"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default EmsSectionTwo;
