import React from "react";
import OrangeCard from "./OrangeCard";
import orangeLeft from "../Assets/orangeLogoLeft.png";
import orangeCenter from "../Assets/orangeLogoCenter.png";
import orangeRight from "../Assets/orangeLogoRight.png";
import { useTranslation } from "react-i18next";
const OrangeCardSection = () => {
  const { t } = useTranslation();
  return (
    <section
      className="lg:h-[600px] w-[100%] bg-[#eeeeee] py-8"
      id="justFitStudio"
    >
      <h2 className="text-[40px] font-bold flex justify-center items-center text-center">
        {t("sectionTwoHeading")}
      </h2>
      <div className="cardWrapper flex flex-col lg:flex lg:flex-row justify-center items-center mt-14">
        <OrangeCard
          cardHeader="orangeCardHeaderOne"
          cardPar="orangeCardPartOne"
          imgSrc={orangeLeft}
          imgAlt="stopwatch"
        />
        <OrangeCard
          cardHeader="orangeCardHeaderTwo"
          cardPar="orangeCardPartTwo"
          imgSrc={orangeCenter}
          imgAlt="weight loss"
          withBorders
        />

        <OrangeCard
          cardHeader="orangeCardHeaderThree"
          cardPar="orangeCardPartThree"
          imgSrc={orangeRight}
          imgAlt="thumbs up"
        />
      </div>
    </section>
  );
};

export default OrangeCardSection;
