import { useTranslation } from 'react-i18next';
import backgroundVideo from '../Assets/VideoSectionBackground.mp4';
import { useMedia } from 'use-media';
import { Link } from 'react-router-dom';

export const VideoSection = ({ header, paragraph, buttonText, buttonLink }) => {
  const { t } = useTranslation();

  const isDesktop = useMedia({ minWidth: '1024px' });

  return (
    <>
      {isDesktop ? (
        <section className="hidden relative h-auto lg:h-[550px] lg:block">
          <div className="max-w-[1200px] mx-auto px-4 py-20 flex justify-center items-center flex-col lg:block">
            <h2 className="text-3xl mb-6 text-white">{t(header)}</h2>
            <p className="lg:w-80 text-gray-400">{t(paragraph)}</p>
            <Link
              to={buttonLink}
              text={buttonText}
              className="hover:bg-secondary w-[250px] text-center hover:border-secondary  mt-8 hidden lg:block border-[3px]  border-main py-4 px-6 bg-transparent text-main font-bold transition-all ease-in"
            >
              {t(buttonText)}
            </Link>
          </div>
          <video
            autoPlay
            loop
            muted
            className="lg:block absolute -z-50 w-auto min-w-full  top-0 left-0 object-cover h-[550px] brightness-50"
          >
            <source src={backgroundVideo} type="video/mp4" />
            <p>Your browser doesn't support this video</p>
          </video>
        </section>
      ) : (
        false
      )}
    </>
  );
};
