import React from "react";
import { useTranslation } from "react-i18next";

function OrangeCard({ imgSrc, imgAlt, cardHeader, cardPar, withBorders }) {
  const { t } = useTranslation();
  return (
    <div
      className={`w-[400px] flex flex-col  items-center py-4 px-4 lg:py-0 lg:h-[310px] ${
        withBorders
          ? "border-y-2 lg:border-y-0 lg:border-x-2 border-[#d1d1d162]"
          : ""
      }`}
    >
      <div className="h-[80px] w-[80px] lg:h-[100px] lg:w-[100px]">
        <img src={imgSrc} alt={imgAlt} />
      </div>
      <h3 className="text-[25px] font-bold my-8">{t(cardHeader)}</h3>
      <p className="text-center text-[18px]">{t(cardPar)}</p>
    </div>
  );
}

export default OrangeCard;
