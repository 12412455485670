import { Header } from '../../components/Header';
import FooterSection from '../../components/FooterSection';
import React from 'react';
import FloatingLink from '../../components/FloatingLink';
import ArrowUp from '../../components/ArrowUp';
import Hero from '../../components/Hero';
import VideoSectionEMS from './VideoSectionEMS';
import SectionFourEMS from './SectionFourEMS';
import SectionThreeEMS from './SectionThreeEMS';
import CodeLuminis from '../../components/CodeLuminis';
import BetaTest from '../../components/BetaTest';

function HowToEMS() {
  return (
    <>
      <BetaTest />
      <FloatingLink />
      <Header />
      <ArrowUp />

      <Hero
        backgroundImage="bg-howToEMSHero"
        backgroundHeight="h-[500px]"
        backgroundSize="bg-cover bg-local xl:bg-fixed"
        backgroundOverlay="bg-[rgb(0,0,0,0.5)]"
        isAnimated={false}
        secondHeaderTitle="howWorksEMS"
      />

      <SectionThreeEMS />
      <VideoSectionEMS />
      <SectionFourEMS
        titleProps="howWorksSectionFourHeader"
        paragraphProps="howWorksSectionFourParagraph"
      />
      <FooterSection />
      <CodeLuminis />
    </>
  );
}

export default HowToEMS;
