import { Header } from '../../components/Header';
import React from 'react';
import FooterSection from '../../components/FooterSection';
import ResultsSecionOne from './ResultsSecionOne';
import ResultsSectionTwo from './ResultsSectionTwo';
import FloatingLink from '../../components/FloatingLink';
import ArrowUp from '../../components/ArrowUp';
import ResultsSectoinThree from './ResultsSectoinThree';
import ResultsSectionFour from './ResultsSectionFour';
import ResultsSliderSection from './ResultsSliderSection';
import CodeLuminis from '../../components/CodeLuminis';
import BetaTest from '../../components/BetaTest';

function Results() {
  return (
    <>
      <BetaTest />
      <FloatingLink />
      <ArrowUp />
      <Header />
      <ResultsSecionOne />
      <ResultsSectionTwo />
      <ResultsSectoinThree
        resultsSectionHeader="emsResultsPageHealthyBackHeading"
        resultsSectionParagraph="emsResultsPageHealthyBackParagraph"
      />
      <ResultsSectionFour />
      <ResultsSliderSection />
      <FooterSection />
      <CodeLuminis />
    </>
  );
}

export default Results;
