import { RouteComponents } from "./Routes";
import {
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
} from "react-router-dom";

const router = createBrowserRouter([{ path: "*", Component: Root }]);

function Root() {
  return (
    <>
      <RouteComponents /> <ScrollRestoration />
    </>
  );
}

export default function App() {
  return <RouterProvider router={router} />;
}
