import React from 'react';
import { useTranslation } from 'react-i18next';

function PhotosSectionTwo() {
  const { t } = useTranslation();
  return (
    <div id="justFitStudio" className="p-5 lg:p-10 w-[100%] bg-[#596161]">
      <div className="titleWrapper FLEX items-center justify-center text-white text-center">
        <h2 className="text-[50px] font-bold mb-12">
          {t('photoesAndVideosSecondSectionHeader')}
        </h2>
        <p className="text-[25px] font-thin">
          {t('photoesAndVideosSecondSectionParagraph')}
        </p>
      </div>
      <div className="videoWrapper w-[100%] h-[60%] mt-16 flex flex-col lg:flex-row justify-center items-center">
        <iframe
          src="https://www.youtube.com/embed/5JHTOytoFZA"
          title="YoutubeVideo"
          className="aspect-auto h-[200px] md:h-[300px] w-[100%] xl:w-[40%]  lg:mr-20"
        ></iframe>
        <iframe
          src="https://www.youtube.com/embed/fnC-fsky1yw"
          title="YoutubeVideo"
          className="aspect-auto h-[200px] md:h-[300px] w-[100%] xl:w-[40%] mt-16 lg:mt-0"
        ></iframe>
      </div>
    </div>
  );
}

export default PhotosSectionTwo;
