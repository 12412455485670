import React from 'react';
import { useTranslation } from 'react-i18next';

function VideoSectionEMS() {
  const { t } = useTranslation();
  return (
    <div className="py-20 bg-[#eeeeee]">
      <div className="titleWrapper flex flex-col justify-center items-center ">
        <h2 className="text-[26px] md:text-[36px] text-center font-bold mb-4">
          {t('howWorksSectionThreeHeader')}
        </h2>
        <p className="text-[14px] md:text-[20px] text-center font-thin">
          {t('howWorksSectionThreeParagraph')}
        </p>
      </div>
      <div className="videoWrapper flex justify-center items-center mt-10">
        <iframe
          className="lg:h-[600px] h-[300px] lg:w-[70%] w-[400px]"
          src="https://www.youtube.com/embed/C9XugQIi_l8"
          title="YoutubeVideo"
        ></iframe>
      </div>
    </div>
  );
}

export default VideoSectionEMS;
