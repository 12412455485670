import React from 'react';
import EmsimageTwo from '../../Assets/emsSectionThreeImageTwo.jpg';
import EmsPropsFile from './EmsPropsFile';

function EmsSectionThreePartTwo() {
  return (
    <div className="bg-[#eeeeee]  w-[100%] p-5 flex ">
      <div className="imageWrapper w-[100%] my-10 flex flex-col lg:flex-row-reverse items-center justify-center">
        <img
          className="lg:ml-4 md:h-[470px] lg:h-[360px] xl:h-[460px] md:w-[600px] lg:w-[460px] xl:w-[560px]"
          src={EmsimageTwo}
          alt="emsImageOne"
        />

        <div className="propsWrapper mt-10 lg:mt-0 lg:mr-4 md:w-[600px] flex flex-col items-center justify-center">
          <div>
            <EmsPropsFile
              emsLogoImage="bg-emsLogoFour"
              emsHeaderTitle="aboutEMSSectionThreeTitleFour"
              emsParagraphTitle="aboutEMSSectionThreeSecondaryTitleFour"
            />
          </div>
          <div className="my-8">
            <EmsPropsFile
              emsLogoImage="bg-emsLogoFive"
              emsHeaderTitle="aboutEMSSectionThreeTitleFive"
              emsParagraphTitle="aboutEMSSectionThreeSecondaryTitleFive"
            />
          </div>
          <div>
            <EmsPropsFile
              emsLogoImage="bg-emsLogoSix"
              emsHeaderTitle="aboutEMSSectionThreeTitleSix"
              emsParagraphTitle="aboutEMSSectionThreeSecondaryTitleSix"
            />
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default EmsSectionThreePartTwo;
