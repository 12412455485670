import React from 'react';
import BackgroundGridComponent from './BackgroundGridComponent';
import { useTranslation } from 'react-i18next';

function EmsBackgroundSection() {
  const { t } = useTranslation();
  return (
    <div className="hidden lg:flex  w-[100%]">
      <div className="bg-sectionOne  bg-cover bg-no-repeat h-[100%] w-[100%]">
        <div className="h-[100%] w-[100%] px-10 bg-[#000000e1] text-white py-4">
          <div className="titleWrapper h-[50%] w-[100%] flex flex-col justify-center items-center">
            <h2 className="text-[48px] text-center font-bold w-[390px]  mb-9">
              {t('aboutEMSBackgroundSectionHeader')}
            </h2>
            <p className="text-[18px] font-bold">
              {t('aboutEMSBackgroundSectionSecondaryHeader')}
            </p>
          </div>
          <div className="gridWrapper h-[50%] w-[100%] mt-28 flex flex-row items-center justify-center">
            <BackgroundGridComponent
              backgroundGridWidth="w-[30%]"
              backgroundGridImage="bg-freedomImage"
              backgroundGridHeader="aboutEMSBackgroundSectionHeaderOne"
              backgroundGridParagraph="aboutEMSBackgroundSectionParagraphOne"
            />
            <BackgroundGridComponent
              backgroundGridWidth="w-[30%]"
              backgroundGridImage="bg-resultImage"
              backgroundGridHeader="aboutEMSBackgroundSectionHeaderTwo"
              backgroundGridParagraph="aboutEMSBackgroundSectionParagraphTwo"
            />
            <BackgroundGridComponent
              backgroundGridWidth="w-[30%]"
              backgroundGridImage="bg-progressImage"
              backgroundGridHeader="aboutEMSBackgroundSectionHeaderThree"
              backgroundGridParagraph="aboutEMSBackgroundSectionParagraphThree"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmsBackgroundSection;
