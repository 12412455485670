import React, { useState } from 'react';
import ResultCards from './ResultCards';
import { SlArrowDown } from 'react-icons/sl';
import { useTranslation } from 'react-i18next';

function ResultsSectionTwo() {
  const [toggle, setToggle] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <div className="h-[200px] w-[100%] bg-[#eeeeee] flex flex-col sm:flex-row justify-center items-center">
        <button
          className={`${
            toggle ? 'bg-white' : 'bg-black text-white'
          } h-[60px] w-[220px] sm:rounded-l-lg rounded-t-lg sm:rounded-tr-none`}
          onClick={() => setToggle(false)}
        >
          {t('emsResultsPageWoman')}
        </button>
        <button
          className={`${
            toggle ? 'bg-black text-white' : 'bg-white'
          } h-[60px] w-[220px] sm:rounded-r-lg rounded-b-lg sm:rounded-bl-none`}
          onClick={() => setToggle(true)}
        >
          {t('emsResultsPageMan')}
        </button>
      </div>
      <div
        className={`${
          toggle
            ? 'bg-resultsSectionTwoImageTwo'
            : 'bg-resultsSectionTwoImageOne'
        } w-[100%] h-[1500px]  bg-fixed  min-[1500px]:bg-[size:98%] bg-no-repeat bg-cover bg-[center_top_0,5rem] flex justify-center items-center flex-row`}
      >
        <div className="h-[100%] w-[100%] bg-[rgba(0,0,0,0.1)]">
          <div
            className={`${
              toggle
                ? 'bg-resultsSectionTwoImageTwo'
                : 'bg-resultsSectionTwoImageOne'
            } lg:bg-none firstCard h-[500px] w-[100%] bg-[center_top_0,5rem] bg-cover`}
          >
            <div className="card1Wrapper h-[100%]">
              <ResultCards
                toggle={toggle}
                titleSecondary={`${
                  !toggle
                    ? 'emsResultsPageFirstCardHeaderWoman'
                    : 'emsResultsPageFirstCardHeaderMan'
                }`}
                titleMain={`${
                  toggle
                    ? 'emsResultsPageFirstCardSecondaryHeaderMan'
                    : 'emsResultsPageFirstCardSecondaryHeaderWoman'
                }`}
                paragraphMain={`${
                  !toggle
                    ? 'emsResultsPageFirstCardParagraphMainWoman'
                    : 'emsResultsPageFirstCardParagraphMainMan'
                }`}
                paragraphList1={`${
                  !toggle
                    ? 'emsResultsPageFirstCardParagraphList1Woman'
                    : 'emsResultsPageFirstCardParagraphList1Man'
                }`}
                paragraphList2={`${
                  !toggle
                    ? 'emsResultsPageFirstCardParagraphList2Woman'
                    : 'emsResultsPageFirstCardParagraphList2Man'
                }`}
                paragraphList3={`${
                  !toggle
                    ? 'emsResultsPageFirstCardParagraphList3Woman'
                    : 'emsResultsPageFirstCardParagraphList3Man'
                }`}
                paragraphList4={`${
                  !toggle
                    ? 'emsResultsPageFirstCardParagraphList4Woman'
                    : 'emsResultsPageFirstCardParagraphList4Man'
                }`}
                paragraphList5={`${
                  !toggle
                    ? 'emsResultsPageFirstCardParagraphList5Woman'
                    : 'emsResultsPageFirstCardParagraphList5Man'
                }`}
              />
            </div>
          </div>
          <div
            className={`${
              toggle
                ? 'bg-resultsSectionTwoImageTwo'
                : 'bg-resultsSectionTwoImageOne'
            } lg:bg-none firstCard h-[500px] w-[100%] bg-[center_top_0,5rem] bg-cover flex justify-end`}
          >
            <div className="">
              <ResultCards
                toggle={toggle}
                titleSecondary={`${
                  !toggle
                    ? 'emsResultsPageSecondCardHeaderWoman'
                    : 'emsResultsPageSecondCardHeaderMan'
                }`}
                titleMain={`${
                  toggle
                    ? 'emsResultsPageSecondCardSecondaryHeaderMan'
                    : 'emsResultsPageSecondCardSecondaryHeaderWoman'
                }`}
                paragraphMain={`${
                  !toggle
                    ? 'emsResultsPageSecondCardParagraphMainWoman'
                    : 'emsResultsPageSecondCardParagraphMainMan'
                }`}
                paragraphList1={`${
                  !toggle
                    ? 'emsResultsPageSecondCardParagraphList1Woman'
                    : 'emsResultsPageSecondCardParagraphList1Man'
                }`}
                paragraphList2={`${
                  !toggle
                    ? 'emsResultsPageSecondCardParagraphList2Woman'
                    : 'emsResultsPageSecondCardParagraphList2Man'
                }`}
                paragraphList3={`${
                  !toggle
                    ? 'emsResultsPageSecondCardParagraphList3Woman'
                    : 'emsResultsPageSecondCardParagraphList3Man'
                }`}
                paragraphList4={`${
                  !toggle
                    ? 'emsResultsPageSecondCardParagraphList4Woman'
                    : 'emsResultsPageSecondCardParagraphList4Man'
                }`}
                paragraphList5={`${
                  !toggle ? '' : 'emsResultsPageSecondCardParagraphList5Man'
                }`}
              />
            </div>
          </div>
          <div
            className={`${
              toggle
                ? 'bg-resultsSectionTwoImageTwo'
                : 'bg-resultsSectionTwoImageOne'
            } lg:bg-none firstCard h-[500px] w-[100%] bg-[center_top_0,5rem] bg-cover flex flex-row relative`}
          >
            <div className="">
              <ResultCards
                toggle={toggle}
                titleSecondary={`${
                  !toggle
                    ? 'emsResultsPageThirdCardHeaderWoman'
                    : 'emsResultsPageThirdCardHeaderMan'
                }`}
                titleMain={`${
                  toggle
                    ? 'emsResultsPageThirdCardSecondaryHeaderMan'
                    : 'emsResultsPageThirdCardSecondaryHeaderWoman'
                }`}
                paragraphMain={`${
                  !toggle
                    ? 'emsResultsPageThirdCardParagraphMainWoman'
                    : 'emsResultsPageThirdCardParagraphMainMan'
                }`}
                paragraphList1={`${
                  !toggle
                    ? 'emsResultsPageThirdCardParagraphList1Woman'
                    : 'emsResultsPageThirdCardParagraphList1Man'
                }`}
                paragraphList2={`${
                  !toggle
                    ? 'emsResultsPageThirdCardParagraphList2Woman'
                    : 'emsResultsPageThirdCardParagraphList2Man'
                }`}
                paragraphList3={`${
                  !toggle
                    ? 'emsResultsPageThirdCardParagraphList3Woman'
                    : 'emsResultsPageThirdCardParagraphList3Man'
                }`}
                paragraphList4={`${
                  !toggle
                    ? 'emsResultsPageThirdCardParagraphList4Woman'
                    : 'emsResultsPageThirdCardParagraphList4Man'
                }`}
                paragraphList5={`${
                  !toggle
                    ? 'emsResultsPageThirdCardParagraphList5Woman'
                    : 'emsResultsPageThirdCardParagraphList5Man'
                }`}
                paragraphList6={`${
                  !toggle ? 'emsResultsPageThirdCardParagraphList6Woman' : ''
                }`}
              />
            </div>
            <div className="anchorWrapper absolute z-10 h-[500px] w-[100%] flex justify-center items-end ">
              <a
                className="cursor-pointer text-[#b3d235] hover:text-[rgb(202,202,202)] transition-all"
                href="/results#resultSectionThree"
              >
                <SlArrowDown size={40} className="animate-upDown mb-10" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResultsSectionTwo;

//{`${!toggle ? "visible" : "invisible"}`}

//<div className={`${toggle ? "visible" : "invisible"}`}><ResultCards toggle={toggle} titleMain="Zviad Shengelia Mgelia"/></div>

//// color choice green - #b3d235 orange - #fe7419
