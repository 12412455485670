import { Route, Routes } from 'react-router';
import { Main } from '../views/Main';
import ThankYou from '../views/ThankYou';
import Results from '../views/Results/Results';
import AboutEms from '../views/aboutEms/AboutEms';
import PhotosAndVideos from '../views/photosAndVideos/PhotosAndVideos';
import HowToEMS from '../views/howToEms/HowToEMS';
import ResearchResults from '../views/researchResults/ResearchResults';
// import {PricesEMS} from '../views/PricesEMS'
import Faq from '../views/faq/Faq';
import { Contact } from '../views/Contact';

import UnderConstruction from '../views/UnderConstruction';

export const RouteComponents = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/thankyou" element={<ThankYou />} />
      <Route path="/UnderConstruction" element={<UnderConstruction />} />
      <Route path="/results" element={<Results />} />
      <Route path="/aboutEms" element={<AboutEms />} />
      <Route path="/photosAndVideos" element={<PhotosAndVideos />} />
      <Route path="/howToEMS" element={<HowToEMS />} />
      <Route path="/researchResults" element={<ResearchResults />} />
      {/* <Route path="/pricesEMS" element={<PricesEMS />} /> */}
      <Route path="/faq" element={<Faq />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
};
