import { useNavigate } from 'react-router-dom';
import { BsHammer } from 'react-icons/bs';
const UnderConstruction = ({ hasGoBackButton }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="underConstruction bg-thankYouImage bg-cover bg-no-repeat h-[100vh] w-[100%] text-white">
        <div className="overlay bg-[rgb(0,0,0,0.60)] w-[100%] h-[100%] flex flex-col items-center justify-center">
          <h1 className="text-[40px] font-roboto font-bold">
            Under Construction
          </h1>
          <BsHammer size={30} className="text-white animate-bounce mt-4" />
          {hasGoBackButton ? (
            <button
              onClick={() => navigate(-1)}
              className="w-[200px] h-[60px] mt-14 border-solid border-[2.5px] border-[#b3d235] text-[#b3d235] flex justify-center items-center cursor-pointer hover:text-white hover:border-none hover:bg-[#fe7419] rounded-[2%] font-bold text-[25px] transition-all duration-300"
            >
              GO BACK
            </button>
          ) : (
            false
          )}
        </div>
      </div>
    </>
  );
};

export default UnderConstruction;
