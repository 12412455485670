import React, { useRef } from "react";
import { useIsInViewport } from "../hooks/useIsViewport";
import { useTranslation } from "react-i18next";

function ResultsCard({ headerTitle, secondaryTitle, bgImage }) {
  const { t } = useTranslation();
  const divRef = useRef(null);

  const isVisible = useIsInViewport(divRef); // ეს არის ბულიან ცვლადი, რომლის კონდიციით შეგიძლია ნებისმიერი კლასი ან ანიმაცია დაუმატო ან მოუხსნა ელემენტს

  return (
    <div
      className={`resultsCardWrapper h-[250px] w-[330px] bg-cover text-white ${bgImage} result-card  
      }`}
      ref={divRef}
    >
      <div className={`h-[100%] w-[100%] bg-[#00000057] text-center `}>
        <div
          className={`${
            isVisible
              ? "opacity-1 translate-y-[0px] delay-[200ms]"
              : "opacity-0 translate-y-[100px]"
          } flex flex-col justify-center items-center h-full transition-all  duration-[2500ms]`}
        >
          <h2 id="headerTitle" className="text-[22px] mb-6 font-bold w-[80%]">
            {t(headerTitle)}
          </h2>
          <p id="secondaryTitle">{t(secondaryTitle)}</p>
        </div>
      </div>
    </div>
  );
}

export default ResultsCard;
