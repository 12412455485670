import React from 'react';
import { useTranslation } from 'react-i18next';

function ResultsSecionOne() {
  const { t } = useTranslation();
  return (
    <div
      id="justFitStudio"
      className="h-[500px] w-[100%] bg-resultsSectionOne lg:bg-[size:140%] bg-[center_top_0rem] bg-fixed"
    >
      <div className="h-[100%] w-[100%] bg-[rgba(0,0,0,0.4)] flex justify-center">
        <div className="textWrapper h-[100%] w-[70%] md:[50%] flex flex-col justify-center items-center">
          <h1 className="text-white text-center font-bold md:text-[52px] text-[35px] mb-12">
            {t('emsResultsPageHeaderHeading')}
          </h1>
          <p className="text-white md:text-[24px] text-[18px] text-center">
            {t('emsResultsPageHeaderParagraph')}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ResultsSecionOne;
