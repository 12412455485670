import { Header } from '../../components/Header';
import React from 'react';
import FooterSection from '../../components/FooterSection';
import Hero from '../../components/Hero';
import EmsSectionTwo from './EmsSectionTwo';
import EmsSectionThree from './EmsSectionThree';
import EmsSectionThreePartTwo from './EmsSectionThreePartTwo';
import ButtonPropsSection from './ButtonPropsSection';
import ResultsSectoinThree from '../Results/ResultsSectoinThree';
import EmsBackgroundSection from './EmsBackgroundSection';
import FloatingLink from '../../components/FloatingLink';
import ArrowUp from '../../components/ArrowUp';
import CodeLuminis from '../../components/CodeLuminis';
import BetaTest from '../../components/BetaTest';
function AboutEms() {
  return (
    <>
      <BetaTest />
      <FloatingLink />
      <ArrowUp />
      <Header />
      <Hero
        backgroundSize="bg-[cover]"
        backgroundHeight="h-[500px]"
        backgroundPosition="bg-[center_top_0rem]"
        backgroundOverlay="bg-[rgb(0,0,0,0.40)]"
        backgroundImage="bg-emsSectionHeroBackground"
        secondHeaderTitle="aboutEMSHeader"
        paragraphTitle="aboutEMSParagraph"
        isAnimated={false}
        arrowOn={false}
      />
      <EmsSectionTwo />
      <EmsSectionThree />
      <EmsSectionThreePartTwo />
      <ButtonPropsSection
        buttonBackground=""
        buttonProps="researchResults"
        buttonParagraph="aboutEMSButtonTitleOne"
        buttonLink="/researchResults"
      />
      <ResultsSectoinThree
        resultSectionBackground="bg-[#eeeeee]"
        resultsSectionHeader="aboutEMSResultSectionHeader"
        resultsSectionParagraph="aboutEMSResultSectionParagraph"
      />
      <EmsBackgroundSection />
      <ButtonPropsSection
        buttonBackground=""
        buttonProps="aboutEMSButtonTextTwo"
        buttonParagraph="aboutEMSButtonTitleTwo"
        buttonLink="/howToEms"
      />
      <FooterSection />
      <CodeLuminis />
    </>
  );
}

export default AboutEms;
