import React from 'react';

function gridSection() {
  return (
    <div className="p-14 flex flex-col justify-center items-center">
      <div className="WrapperOne ">
        <div className="flex flex-row justify-center">
          <div className="gridImageONe xl:h-[500px] lg:h-[408px] md:h-[261px] h-[157px] xl:w-[760px] lg:w-[620px] md:w-[396px] w-[237px] bg-gridImageBackgroundOne bg-no-repeat bg-cover bg-center mr-[25px] mb-[25px]"></div>
          <div className="gridImageONe xl:h-[500px] lg:h-[408px] md:h-[261px] h-[157px] xl:w-[370px] lg:w-[303px] md:w-[192px] w-[112px] bg-gridImageBackgroundTwo bg-cover bg-no-repeat bg-center"></div>
        </div>

        <div className="flex flex-row justify-center ">
          <div className="gridImageONe xl:h-[500px] lg:h-[408px] md:h-[261px] h-[157px] xl:w-[370px] lg:w-[303px] md:w-[192px] w-[112px] bg-gridImageBackgroundThree bg-cover bg-no-repeat bg-center mr-[25px]"></div>
          <div className="gridImageONe xl:h-[500px] lg:h-[408px] md:h-[261px] h-[157px] xl:w-[760px] lg:w-[620px] md:w-[396px] w-[237px] bg-gridImageBackgroundFour bg-cover bg-no-repeat bg-center"></div>
        </div>

        <div className="grid md:grid-cols-3 grid-cols-1 gap-[37.56px] mt-[25px]">
          <div className="xl:h-[360px] lg:h-[320px] h-[360px] xl:w-[360px] bg-gridImageBackgroundFive bg-cover bg-center"></div>
          <div className="xl:h-[360px] lg:h-[320px] h-[360px] xl:w-[360px] bg-gridImageBackgroundSix bg-cover bg-center"></div>
          <div className="xl:h-[360px] lg:h-[320px] h-[360px] xl:w-[360px] bg-gridImageBackgroundSeven bg-cover bg-center"></div>
        </div>
      </div>
    </div>
  );
}

export default gridSection;
