import { useTranslation } from "react-i18next";

function ResultCards({
  titleMain,
  titleSecondary,
  paragraphMain,
  paragraphList1,
  paragraphList2,
  paragraphList3,
  paragraphList4,
  paragraphList5,
  paragraphList6,
  toggle,
}) {
  const { t } = useTranslation();
  return (
    <div className="h-[500px] w-[100%] md:w-[550px] bg-[#ffffff] opacity-[0.9] md:opacity-[0.9] border-y-2">
      <div className="px-8 md:px-16 py-4">
        <h3
          id="titleSecondary"
          className={`${
            !toggle ? "text-[#fe7419]" : "text-[#b3d235]"
          } text-[14px] tracking-[4px] font-bold`}
        >
          {t(titleSecondary)}
        </h3>
        <h2
          id="titleMain"
          className={`${
            !toggle ? "text-[#fe7419]" : "text-[#b3d235]"
          } min-[470px]:text-[27px] text-[24px] font-bold my-2 min-[470px]:my-4`}
        >
          {t(titleMain)}
        </h2>
        <p className="mb-2 min-[450px]:mb-6">{t(paragraphMain)}</p>
        <ul className="flex flex-col">
          <li>{`${t(paragraphList1)}`}</li>
          <li className="my-0 min-[450px]:my-2">{`${t(paragraphList2)}`}</li>
          <li className="mb-0 min-[450px]:mb-2">{`${t(paragraphList3)}`}</li>
          <li className="mb-0 min-[450px]:mb-2">{`${t(paragraphList4)} `}</li>
          <li>{`${t(paragraphList5)}`}</li>
          <li>{`${t(paragraphList6)}`}</li>
        </ul>
      </div>
    </div>
  );
}

export default ResultCards;
