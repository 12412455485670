import React from 'react';
import { PiArrowBendDownRightLight } from 'react-icons/pi';
import { HiMiniArrowLongDown } from 'react-icons/hi2';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function SectionFourEMS({ titleProps, paragraphProps }) {
  const { t } = useTranslation();
  return (
    <div className=" bg-howToEMSSectionFour bg-cover h-[100%] w-[100%]">
      <div className="overlay h-[100%] w-[100%] bg-[rgb(0,0,0,0.5)] py-20 px-30 lg:py-28 lg:px-60">
        <div className="titleWrapper text-white flex flex-col justify-center items-center ">
          <h2 className="text-[30px] md:text-[48px] text-center font-bold mb-4">
            {t(titleProps)}
          </h2>
          <p className="text-[18px] md:text-[24px] text-center font-thin">
            {t(paragraphProps)}
          </p>
        </div>
        <div className="buttonWrapper relative flex flex-col lg:flex-row justify-center items-center mt-10">
          <div>
            <PiArrowBendDownRightLight
              size={60}
              className="arrow absolute invisible lg:visible text-[#b3d235]"
            />
            <HiMiniArrowLongDown
              size={60}
              className="arrow lg:invisible text-[#b3d235]"
            />
          </div>
          <Link
            to="/#contactForm"
            className="w-[340px] h-[60px]  border-solid border-[2.5px] border-[#b3d235] text-[#b3d235] hover:text-white hover:border-none hover:bg-[#fe7419] rounded-[2%] font-bold transition-all duration-300 flex justify-center items-center"
          >
            {t('pricesBlackSectionButton')}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SectionFourEMS;
