import React from 'react'

function PhotosSectionThree() {
  return (
    <div className='flex justify-center items-center px-6 py-16 bg-[#eeeeee]'>
        <iframe className='lg:h-[600px] h-[300px] lg:w-[70%] w-[400px]' src='https://www.youtube.com/embed/WQWZRmgb73c' title="YoutubeVideo" ></iframe>
    </div>
  )
}

export default PhotosSectionThree