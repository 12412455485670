import { clsx } from "clsx";
import { useTranslation } from "react-i18next";

export const MainButton = ({ text, className }) => {
  const { t } = useTranslation();
  return (
    <button
      className={clsx(
        "border-[3px] rounded border-main py-2 px-6 bg-transparent text-main font-bold transition-all ease-in",
        className
      )}
    >
      {t(text)}
    </button>
  );
};
