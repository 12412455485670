import React from 'react';
import CardsPropsComponent from './CardsPropsComponent';
import { useTranslation } from 'react-i18next';

function ResearchCardSection() {
  const { t } = useTranslation();
  return (
    <div className="bg-[#eeeeee] flex flex-col justify-center items-center py-16 px-10">
      <div className="textWrapper w-[80%] lg:w-[50%] flex flex-col items-center justify-center">
        <h2 className="text-[40px] text-center font-bold mb-10">
          {t('researchResultsSportSectionHeader')}
        </h2>
        <p className="text-[22px] text-center font-thin w-[90%] lg:w-[70%]">
          {t('researchResultsSportSectionParagraph')}
        </p>
      </div>
      <div className="w-[100%] flex justify-center">
        <div className="propsWrapper mt-20 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-10">
          <CardsPropsComponent
            cardsBackgroundSize="bg-[size:140%]"
            cardsBackground="bg-researchCardOne"
            textBackground="bg-white"
            cardsThirdHeader="researchResultsSportSectionCardOneHeader"
            cardsParagraphMain="researchResultsSportSectionCardOneParagraph"
          />
          <CardsPropsComponent
            cardsBackgroundSize="bg-cover"
            textWrapperProp="text-white"
            cardsBackground="bg-researchCardTwo"
            cardsThirdHeader="researchResultsSportSectionCardTwoHeader"
            cardsParagraphMain="researchResultsSportSectionCardTwoParagraph"
          />
          <CardsPropsComponent
            cardsBackgroundSize="bg-[size:140%]"
            cardsBackground="bg-researchCardThree"
            textBackground="bg-white"
            cardsThirdHeader="researchResultsSportSectionCardThreeHeader"
            cardsParagraphMain="researchResultsSportSectionCardThreeParagraph"
          />
          <CardsPropsComponent
            cardsBackgroundSize="bg-[size:140%]"
            textBackground="bg-white"
            cardsBackground="bg-researchCardFour"
            cardsThirdHeader="researchResultsSportSectionCardFourHeader"
            cardsParagraphMain="researchResultsSportSectionCardFourParagraph"
          />
          <CardsPropsComponent
            cardsBackgroundSize="bg-cover"
            cardsBackground="bg-researchCardFive"
            textWrapperProp="text-white"
            cardsThirdHeader="researchResultsSportSectionCardFiveHeader"
            cardsParagraphMain="researchResultsSportSectionCardFiveParagraph"
          />
          <CardsPropsComponent
            cardsBackgroundSize="bg-[size:140%]"
            textBackground="bg-white"
            cardsBackground="bg-researchCardSix"
            cardsThirdHeader="researchResultsSportSectionCardSixHeader"
            cardsParagraphMain="researchResultsSportSectionCardSixParagraph"
          />
        </div>
      </div>
    </div>
  );
}

export default ResearchCardSection;
