import React from 'react';
import { useTranslation } from 'react-i18next';

function EmsPropsFile({ emsLogoImage, emsHeaderTitle, emsParagraphTitle }) {
  const { t } = useTranslation();
  return (
    <div className="wrapperMain flex">
      <div>
        <div className="logo flex justify-center items-center h-[50px] w-[50px] mr-8">
          <div className={`${emsLogoImage} bg-cover h-[50px] w-[50px]`}></div>
        </div>
      </div>
      <div>
        <h3 className="text-[20px] font-bold mb-4">{t(emsHeaderTitle)}</h3>
        <p className="text-[16px] lg:text-[18px] font-thin">
          {t(emsParagraphTitle)}
        </p>
      </div>
    </div>
  );
}

export default EmsPropsFile;
